import LogoWName from "../../assets/cplogo.png";
import React, { useState } from "react";
import "./Footer.css";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
  FaPaperPlane,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactInfo = ({ icon, text, addedStyle }) => (
  <p className="d-flex align-items-center">
    <span className="contactIcon me-3">{icon}</span>
    {text}
  </p>
);

const ContactInfos = [
  {
    icon: <FaMapMarkerAlt />,
    text: "Unit 218 Civic Prime, Civic Drive Filinvest Corporate City, Alabang, Muntinlupa City 1781",
    addedStyle: "",
  },
  {
    icon: <FaEnvelope />,
    text: "ask@cyberpros.ph",
  },
  {
    icon: <FaPhone />,
    text: "+1 (408) 389-8527",
  },
];

const navItems = [
  {
    label: "Services",
    href: "/services",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
  {
    label: "FAQs",
    href: "/FAQs",
  },
  {
    label: "Book a strategy call",
    href: "/book",
  },
  {
    label: "Privacy Policy",
    href: "/privacypolicy2", // Updated link to the new PrivacyPolicy2 page
  },
];

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("FormData before sending:", formData); // Add this line to check the data

    toast
      .promise(axios.post("https://cyberpros.ph/api/contactUs.php", formData), {
        pending: "Sending your message...",
        success: "Message sent successfully!",
        error: "Error sending message! Please try again.",
      })
      .then((response) => {
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
      });
  };

  return (
    <>
      <ToastContainer />
      <footer className="bg-dark text-white py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h5>GET IN TOUCH</h5>
              <p>Our Contact Details:</p>
              <p className="lil-text">
                If you would like to explore possibility of working with us,
                please let us know
              </p>
              <div
                style={{ width: "70%" }}
                className="d-flex flex-column justify-content-between mt-5"
              >
                {ContactInfos.map((info, index) => (
                  <ContactInfo
                    key={index}
                    icon={info.icon}
                    text={info.text}
                    addedStyle={info.addedStyle}
                  />
                ))}
              </div>

              <div className="navi">
                {navItems.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.href} className="nav-link">
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <h5>Contact Us</h5>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="message">
                    Message:
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    style={{ resize: "none" }}
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn angas px-3 rounded-pill mt-4"
                >
                  <FaPaperPlane className="text-light p-0 m-0 me-2" />
                  Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <div className="last container d-flex justify-content-between align-items-center my-3">
        <img src={LogoWName} alt="CyberPros Logo" height={60} />
        <p>&copy; 2024 Cyber Pros. All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;
