import React from "react";
import "./Roles.css";
import { NavLink } from "react-router-dom";
import adminIcon from "../../assets/Icons/admin.png";
import customerIcon from "../../assets/Icons/customer.png";
import marketingIcon from "../../assets/Icons/marketing.png";
import loanIcon from "../../assets/Icons/loan.png";
import salesIcon from "../../assets/Icons/sales.png";
// import recruitIcon from "../../assets/Icons/recruit.png";
import helpdeskIcon from "../../assets/Icons/helpdesk.png";
import virtualIcon from "../../assets/Icons/virtual.png";
import transacIcon from "../../assets/Icons/transac.png";
import gearIcon from "../../assets/Icons/gear.png";

const roles = [
	{
		icon: adminIcon,
		title: "Administrative",
		link: "/administrative-services",
	},
	{
		icon: customerIcon,
		title: "Customer Service",
		link: "/customer-services",
	},
	{
		icon: marketingIcon,
		title: "Marketing",
		link: "/marketing-services",
	},
	{
		icon: loanIcon,
		title: "Inside Sales Agent",
		link: "/inside-sales-agent",
	},
	{
		icon: salesIcon,
		title: "Sales Development",
		link: "/sales-development",
	},
	{
		icon: transacIcon,
		title: "Transaction Coordinator",
		link: "/transaction-coordinator",
	},
	{
		icon: gearIcon,
		title: "Engineering Support",
		link: "/engineering-support",
	},
	{
		icon: virtualIcon,
		title: "IT Support",
		link: "/it-support",
	},
	{
		icon: helpdeskIcon,
		title: "Executive Virtual Assistant",
		link: "/executive-va",
	},
];

const RolesCard = ({ title, icon, link }) => (
	<div className="col-md-4">
		<a className="card text-center text-decoration-none" href={link}>
			<div className="card-body rolex">
				<img src={icon} alt={title} />
				<h5 className="card-title">{title}</h5>
			</div>
		</a>
	</div>
);

const Roles = () => (
	<section id="roles" className="py-5">
		<div className="container">
			<h2 className="text-center">
				Explore <span className="text-primary">Our Roles</span>
			</h2>
			<div className="row mt-4 mx-5">
				{roles.map((role, index) => (
					<RolesCard
						key={index}
						title={role.title}
						icon={role.icon}
						link={role.link}
					/>
				))}
			</div>
			<div className="text-center btn-text mt-4">
				<p className="mx-auto role-text">
					Hire Top <span>Virtual Professionals</span> Today!
				</p>
				<NavLink to="/book" className="btn btnx rounded-pill px-5">
					BOOK NOW
				</NavLink>
			</div>
		</div>
	</section>
);

export default Roles;
