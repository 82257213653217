import React from "react";
import Fillup from "../../assets/Home/Home1/type.png";
import Upload from "../../assets/Home/Home1/upload.png";
import Info from "../../assets/Home/Home1/info.png";
import "./HowToApply.css";

const ApplyList = ({ title, text, icon }) => (
  <div className="col-md-4 px-5 mt-2 text-center">
    <div
      style={{ backgroundColor: "#33c2", height: "70px", width: "70px" }}
      className="d-flex align-items-center justify-content-center rounded-circle mx-auto"
    >
      <img src={icon} alt={title} className="mb-0" height={40} />
    </div>
    <h6 className="card-title my-0 mt-3 mb-1 fw-bold">{title}</h6>
    <p className="card-text my-0" style={{ fontSize: "14px" }}>
      {text}
    </p>
  </div>
);

const HowToApply = () => {
  const steps = [
    {
      icon: Fillup,
      title: "Fill up application Form",
      text: "Begin by completing the application form with your personal and professional details.",
    },
    {
      icon: Upload,
      title: "Upload Resume and Upload Video",
      text: "Upload your updated resume. Additionally, upload a video introducing yourself and highlighting your skills and experiences.",
    },
    {
      icon: Info,
      title: "Submit Application",
      text: "Once all the necessary information and documents are uploaded, review all the information before submitting.",
    },
  ];
  return (
    <div id="howtoApply">
      <div className="text-center">
        <div className="container text-center">
          <div className="fw-bolder fs-4">
            <span className="border border-dark p-3 rounded">
              HOW TO APPLY AT{" "}
              <span className="text-info">CYBERPROS</span>?
            </span>
          </div>

          <div className="mt-5 row mx-auto">
            {steps.map((step, index) => (
              <ApplyList
                key={index}
                title={step.title}
                text={step.text}
                icon={step.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToApply;
