import React from "react";
import Navbar from "../components-PH/Navbar-Phase1/Navbar";
import Footer from "../components-PH/Footer/Footer";
import Welcome from "../components-PH/Phase1_Components/Welcome/Welcome";
// import What from "../components-PH/Phase1_Components/What/What";
import Who from "../components-PH/Phase1_Components/Who/Who";
// import Who1 from "../components-PH/Phase1_Components/Who/Who1";
// import Who2 from "../components-PH/Phase1_Components/Who/Who2";
// import Office from "../components-PH/Phase1_Components/Office/Office";
// import Testimonial1 from "../components-PH/Phase1_Components/Testimonial/Testimonial1";
import What1 from "../components-PH/Phase1_Components/What/What1";
import HowToApply from "../components-PH/HowToApply/HowToApply";
import BlogSection from "../components-PH/Blog/BlogSection";
import SysReq from "../components-PH/SystemRequirements/SysReq";

const Phase1 = () => {
	return (
		<div>
			<Navbar />
			<Welcome />
			{/* <What /> */}
			<Who />
			<HowToApply />
			<BlogSection />
			<SysReq />
			{/* <Who1 /> */}
			{/* <Who2 /> */}
			{/* <Office /> */}
			{/* <Testimonial1 /> */}
			<What1 />
			<Footer />
		</div>
	);
};

export default Phase1;
