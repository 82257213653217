import React from "react";
import "./WelcomeAbout.css";
import grow1 from "../../../assets/About/section 1 overlay.png";

const WelcomeAbout = ({ scrollSuccess }) => {
  return (
    <section id="welcomeAbout">
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-8 col-12 mb-4 mb-md-0">
            <h1
              className="mb-3"
              style={{
                fontWeight: 800,
                fontFamily: "Inter, sans-serif",
                fontSize: "clamp(1.5rem, 4vw, 2.5rem)", // Responsive font size
              }}
            >
              WE HELP YOU TO{" "}
              <span className="text-primary">COMPLETE</span> YOUR
              <span className=""> BEST WORK</span>
            </h1>

            <button
              className="btn rounded-pill px-5"
              onClick={scrollSuccess}
              style={{ fontSize: "clamp(0.9rem, 2vw, 1.1rem)" }} // Responsive font size
            >
              What We Do
            </button>
          </div>
          <div className="col-lg-6 col-md-4 col-12 d-flex justify-content-center">
            <img
              src={grow1}
              alt="Grow your Business"
              className="img-fluid growPic rounded"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeAbout;
