import React from "react";

const TabPlain = ({ asIs, addedClass }) => {
	return (
		<div className="mt-3">
			<div className={`mx-2 ${addedClass}`}>
				{asIs.map((asIs, index) => (
					<blockquote key={index}>{asIs}</blockquote>
				))}
			</div>
		</div>
	);
};

export default TabPlain;
