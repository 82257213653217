import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import MainUI from "../Inudstry-UI/IndustryMain";
import SMMIcon from "../../../assets/Industry/Marketing/SMM.png";
import OMIcon from "../../../assets/Industry/Marketing/OM.png";
import EMIcon from "../../../assets/Industry/Marketing/EM.png";
import SBMIcon from "../../../assets/Industry/Marketing/SBM.png";
import VPEIcon from "../../../assets/Industry/Marketing/VPE.png";
import CDIcon from "../../../assets/Industry/Marketing/CD.png";

const MarketingService = () => {
	const btns = [
		{
			target: "SMM",
			label: "Social Media Management",
		},
		{
			target: "OM",
			label: "Online Marketing",
		},
		{
			target: "EM",
			label: "Email Marketing",
		},
		{
			target: "SBM",
			label: "Site & Blog Management",
		},
		{
			target: "VPE",
			label: "Video Production & Editing",
		},
		{
			target: "CD",
			label: "Collateral Development",
		},
	];

	const contents = [
		{
			target: "SMM",
			icon: SMMIcon,
			title: "Social Media Management",
			tasks: [
				"Oversee social media platforms, including YouTube, Pinterest, Linked In, Facebook, and Twitter",
				"Engage potential customers on the company's social media accounts by leaving comments on posts and responding to tweets or comments",
				"Increase the number of followers on Twitter, likes on Facebook and Instagram, and other social media accounts for the business",
			],
		},
		{
			target: "OM",
			icon: OMIcon,
			title: "Online Marketing",
			tasks: [
				"Process results in suitable campaigns and campaign performance reports",
				"Produce insightful content to increase website traffic",
				"Campaign, website, and traffic data report",
			],
		},
		{
			target: "EM",
			icon: EMIcon,
			title: "Email Marketing",
			tasks: [
				"Handle drip campaign content and convert leads into relevant campaigns",
				"Whether it's an online or print version, create, select, and update your newsletter on a regular basis",
				"Provide data on email campaign performance, including conversions, open rates, and response rates",
			],
		},
		{
			target: "SBM",
			icon: SBMIcon,
			title: "Site & Blog Management",
			tasks: [
				"Content creation, curation, aggregation, and editing are all included in blog management",
				"Make pages and forms for generating leads",
				"Analyze and report on the effectiveness of pages and campaigns",
			],
		},
		{
			target: "VPE",
			icon: VPEIcon,
			title: "Video Production & Editing",
			tasks: [
				"Organize and edit multimedia, including audio and video, for websites, social media accounts, and YouTube channels",
				"Edit and Share Webinars and Podcasts on Apple, Spotify, and SoundCloud",
				"Edit and post TikTok, Instagram, and Youtube shorts",
			],
		},
		{
			target: "CD",
			icon: CDIcon,
			title: "Collateral Development",
			tasks: [
				"To increase the number of quality leads that come into your business, make flyers, direct mail pieces, and graphics",
				"Make ad posting templates and publish them on social media platforms such as Facebook, Craigslist, Instagram, Twitter, and so forth.",
				"Retouch films and other high-quality material to increase brand recognition.",
			],
		},
	];

	const paragraphs = [
		"Your success depends on your ability to market. Online marketing has become a crucial component of any marketing strategy, in addition to traditional channels. Managing all of your emails, blogs, websites, social media accounts, and other online activities requires time and effort, but you don't have to!",
		"A marketing VP concentrates on establishing your company's visibility, engagement, and appropriate branding. They help your sales team, improve your online presence, and take care of the tedious daily tasks associated with marketing. Recruiting a VP for your marketing agency or for your company is an excellent method to make sure that your branding, website, ads, and online presence are seen by as many people as possible.",
		"Cyber Pros VPs are skilled professionals that put in a lot of effort to make sure your online presence is visually appealing to your audience and reaches as many potential clients as possible.",
	];
	return (
		<div>
			<Navbar />
			<MainUI
				title="MARKETING"
				ribbon="Use a Virtual Professional (VP) in Marketing to Reach Your Audience"
				cardTitle="What An Marketing Virtual Professional Can Do For You"
				paragraphs={paragraphs}
				tabInit="SMM"
				btns={btns}
				contents={contents}
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default MarketingService;
