import React from "react";
import "./Who.css";

const Who = () => {
  return (
    <section id="who" className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-10 mx-auto">
            <div className="d-flex flex-column flex-md-row">
              <div className="px-3 text-light">
                <h5 className="lil-text">Join Cyber Pros</h5>
                <h1 className="who-text">
                  ELEVATE YOUR CAREER AS A&nbsp;
                  <span style={{ color: "#2EDBE2" }}>
                    VIRTUAL PROFESSIONALS TODAY!
                  </span>
                </h1>
              </div>
              <div className="px-3">
                <div className="px-3 text-light">
                  <p className="lil-text">
                    Welcome to Cyber Pros, where your career aspirations meet
                    unparalleled flexibility and growth. We’re on a mission to
                    empower talented individuals like you to achieve
                    professional success from the comfort of your home. Discover
                    why Cyber Pros is the perfect place to take your career to
                    the next level.
                  </p>
                  <p className="lil-text">
                    If you’re passionate, dedicated, and ready to take your
                    career to new heights, Cyber Pros is the place for you. Apply
                    now and become a valued member of our thriving virtual team.
                    Together, we’ll achieve great things and make a significant
                    impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Who;
