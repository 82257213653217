import React from "react";
import "./IndustryNav.css";

const NavTabs = ({ label, target }) => {
  return (
    <button
      className={`nav-link ${target === "accbook" ? "active" : ""}`}
      id={`${target}-tab`}
      data-bs-toggle="pill"
      data-bs-target={`#${target}`}
      role="tab"
      aria-controls={target}
      aria-selected={target === "accbook" ? "true" : "false"}
    >
      {label}
    </button>
  );
};

const TabContent = ({
  title,
  content,
  target,
  addedLink,
  secSentence,
  secContent,
  lists,
}) => {
  return (
    <div
      className={`tab-pane fade show ${target === "accbook" ? "active" : ""}`}
      id={target}
      role="tabpanel"
      aria-labelledby={`${target}-tab`}
    >
      <h4 style={{ color: "var(--main-color-hover)" }}>{title}</h4>
      <p style={{ fontSize: "14px" }}>{content}</p>
      {secSentence ? <p>{secSentence}</p> : ""}
      {addedLink && addedLink.length > 0
        ? addedLink.map((link, index) => (
            <>
              <a key={index} href={link.href}>
                {link.linkLabel}
              </a>
              <br />
            </>
          ))
        : ""}
      {secContent ? (
        <p style={{ fontSize: "14px" }}>
          <br />
          {secContent}
        </p>
      ) : (
        ""
      )}
      {lists && lists.length > 0 ? (
        <ul>
          {lists.map((list, index) => (
            <li key={index}>{list}</li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};

const IndustryNav = () => {
  const tabs = [
    {
      label: "Accounting and Bookkeeping",
      target: "accbook",
    },
    {
      label: "Engineering/Construction",
      target: "engcons",
    },
    {
      label: "Health Care",
      target: "healthcare",
    },
    {
      label: "Human Resource",
      target: "hr",
    },
    {
      label: "Information and Technology",
      target: "infotech",
    },
    {
      label: "Insurance",
      target: "insurance",
    },
    {
      label: "Legal",
      target: "legal",
    },
    {
      label: "Logistics",
      target: "logistics",
    },
    {
      label: "Marketing",
      target: "marketing",
    },
    {
      label: "Real Estate",
      target: "realestate",
    },
  ];

  const tabContents = [
    {
      title: "ACCOUNTING AND BOOKKEEPING",
      content:
        "A virtual accounting and bookkeeping professional is responsible for preparing and organizing all financial transactions. He or she has experience on financial records keeping. ",
      target: "accbook",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
      ],
      secContent: "Accounting and Bookkeeping tasks includes:",
      lists: [
        "Documenting financial transactions",
        "Reconciling financial discrepancies by collecting or analyzing account information",
        "Recording and Categorizing Expenses",
        "Scheduling client’s bill to be paid on time",
      ],
    },
    {
      title: "ENGINEERING / CONSTRUCTION",
      content:
        "An engineering virtual professional is a highly skilled worker who provides specialized support to engineers and engineering firms remotely. These virtual aides handle a variety of tasks that help streamline operations, improve efficiency, and free up time for engineers to focus on their core activities.  Engineering Professionals  are responsible for the coordination of project resources, the creation of technical drawings, and the creation of reports. They have strong interpersonal and organizational skills. They have working knowledge on CAD software.",
      target: "engcons",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/customer-services",
          linkLabel: "CUSTOMER SERVICES",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONALSERVICES",
        },
      ],
      secContent: "Virtual Engineers tasks include:",
      lists: [
        "Structural Detailing",
        "Quantity Survey (QS)",
        "Building Information Modeling (BIM)",
        "Energy Modeling",
      ],
    },
    {
      title: "HEALTHCARE",
      content:
        "A virtual healthcare professional is one who provides remote support to physician, nurses and other healthcare providers. They operate from a separate location, often from home, and use digital tools to perform their duties.",
      target: "healthcare",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/customer-services",
          linkLabel: "CUSTOMER SERVICES",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONAL SERVICES",
        },
      ],
      secContent:
        "Virtual healthcare professionals carry out variety of tasks, mainly administrative and patient care support. Its tasks include:",
      lists: [
        "Managing medical records, schedule appointments, handling bills, insurance claims and facilitate communication between patient and healthcare providers. ",
        "Assist in medical transcription as directed by doctors",
        "Follow up patients test results",
        "Virtual healthcare professionals ensure smooth operations in a healthcare setting, promoting efficient patient care even from a distance.",
      ],
    },
    {
      title: "HUMAN RESOURCE",
      content:
        "A virtual human resource (HR) professional handles day-to-day HR tasks for businesses. They  recruit new employees, manage records, and conduct payroll duties. Hiring a virtual HR assistant can reduce workload, improve productivity, and ensure compliance while keeping employees happy.",
      target: "hr",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/marketing-services",
          linkLabel: "MARKETING SERVICES",
        },
        {
          href: "/customer-services",
          linkLabel: "CUSTOMER SERVICES",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONAL SERVICES",
        },
      ],
      secContent: "HR Professional tasks include:",
      lists: [
        "Responsible for internal and external communications. ",
        "Handling of documents, Managing recruitment drives and communicating with staffs",
        "Well- versed in troubleshooting company’s employee concern.",
        "Keep employee’s data and discuss benefits with employees ",
        "Perform payrolls duties, collect and record documentation and assist in recruitment operations.",
      ],
    },
    {
      title: "INFORMATION AND TECHNOLOGY",
      content:
        " A information and technology (I.T.)  virtual professional is someone who works remotely to help you manage all the technology your company uses, stay up-to-date on the latest technologies and trends, and recommend which ones are best for your business. They help you implement new technologies to increase your productivity and help your business run smoother.",
      target: "infotech",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONAL SERVICES",
        },
        {
          href: "/it-support",
          linkLabel: "IT SUPPORT",
        },
      ],
      secContent: "An I.T. professional tasks include:",
      lists: [
        "Providing technical support by helping the company understand technology trends, use of existing tools and troubleshooting problems that you may encounter while using them. ",
        "Software testing and Maintenance",
        "Remote technical support and device management",
        "Remote system and network management",
        "Remote Network Operations Center",
        "Remote Security Operations Center",
      ],
    },
    {
      title: "INSURANCE",
      content:
        "An insurance virtual professional helps with  variety of back-office tasks for insurance agencies, such as customer service, billing, and policy changes. By using an insurance virtual professional, you can free up time for your agents and sales team so that you can focus on more important tasks. In addition, using an insurance agent virtual professional can help you save money on overhead costs.",
      target: "insurance",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/marketing-services",
          linkLabel: "MARKETING SERVICES",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONAL SERVICES",
        },
      ],
      secContent: "Virtual Insurance Professional tasks include: ",
      lists: [
        "Administrative tasks such as basic data entry, transcription, file management, email management and research.",
        "Customer Service",
        "Contact potential clients and schedule appointments for your client once ready to purchase or need in depth support.",
        "Preparing Policy Information ",
        "CRM Management and Creating Reports",
        "Lead Generation, Lead Nurturing and Lead Management",
        "Taking Payment",
      ],
    },
    {
      title: "LEGAL",
      content:
        "A virtual legal professional is a qualified individual who works remotely to support law firms, attorneys and other lawyers. These legal virtual professionals are tasked to help with legal research, case management, document preparation and client communication. They are well versed in legal terminology, helping attorneys streamline workflows, improve efficiency and focus on critical legal flexibilities. They can help save costs and work for lawyers by providing specialized expertise on a flexible, as needed basis.",
      target: "legal",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONAL SERVICES",
        },
      ],
      secContent: "Virtual legal professional tasks include: ",
      lists: [
        "Documentation, Maintenance and Organization",
        "In- depth legal research and analvsis. coordinating with clients and scheduling appointments",
        "Claims process and management",
        "Administrative Support, Data Entry and Correspondence ",
        "Costing, Billing and Financial Management",
        "Legal Transcription and Records keeping services",
        "General administrative tasks and planning",
      ],
    },
    {
      title: "LOGISTICS",
      content:
        "A logistics virtual professional  stands at the forefront of innovation, propelled by the integration of remote assistance to streamline logistic operations. These digital aides revolutionize operations, optimizing efficiency and enhancing customer satisfaction. ",
      target: "logistics",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/marketing-services",
          linkLabel: "MARKETING SERVICES",
        },
        {
          href: "/customer-sevices",
          linkLabel: "CUSTOMER SERVICES",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONAL SERVICES",
        },
      ],
      secContent: "Logistics Professional tasks include: ",
      lists: [
        "Order Processing Management",
        "Inventory Management",
        "Shipment tracking and status updates",
        "Route Optimization",
        "Customer Service Support",
        "Data Analysis and Reporting",
        "Compliance and Regulatory Compliance",
      ],
    },
    {
      title: "MARKETING",
      content:
        "A virtual marketing professional is a remote worker who provides services typically performed by an in-house employee or contractor. They  work on many tasks, including social media account management, content writing, keyword research, email marketing, search engine optimization (SEO), website management, PPC campaign management (Facebook ads, Google ads, etc.), and other tasks under the digital marketing umbrella.",
      target: "marketing",
      addedLink: [
        {
          href: "/marketing-services",
          linkLabel: "MARKETING SERVICES",
        },
      ],
      secContent: "Virtual Marketing Professionals tasks include:",
      lists: [
        "Social media  management",
        "Email and Content marketing",
        "Search Engine Optimization",
        "Sales Funnel Creation",
        "PPC Campaign Management",
        "Graphic Design and Media Production",
      ],
    },
    {
      title: "REAL ESTATE",
      content:
        "A virtual professional for real estate manages emails, makes meetings, sets up an appointment, handles social media, creates marketing materials, and conducts property research for real estate companies from a remote location. Virtual Profesional for real estate manage a lot of duties, freeing up your time for other crucial activities.",
      target: "realestate",
      addedLink: [
        {
          href: "/administrative-services",
          linkLabel: "ADMINISTRATIVE SERVICES",
        },
        {
          href: "/inside-sales-agent",
          linkLabel: "INSIDE SALES AGENT",
        },
        {
          href: "/marketing-services",
          linkLabel: "MARKETING SERVICE",
        },
        {
          href: "/executive-va",
          linkLabel: "EXECUTIVE VIRTUAL PROFESSIONAL SERVICES",
        },
        {
          href: "/transaction-coordinator",
          linkLabel: "TRANSACTION COORDINATOR",
        },
      ],
      secContent: "Virtual Real Estate tasks include: ",
      lists: [
        "Calendar Management",
        "Inbox and Schedule Management",
        "Updating Social Media Account",
        "Web Research and Data Entry",
        "Updating CRM",
      ],
    },
  ];

  return (
    <div className="row mt-4 pl-2 pr-2">
      <div className="col-lg-3 col-sm-12 col-md-6">
        <div
          className="nav flex-sm-row flex-md-column nav-pills"
          role="tablist"
          aria-orientation="vertical"
        >
          {tabs.map((tab, index) => (
            <NavTabs
              key={index}
              label={tab.label}
              target={tab.target}
              selected={tab.selected}
            />
          ))}
        </div>
      </div>
      <div className="col-lg-8 col-sm-12 col-md-6">
        <div className="tab-content" id="v-pills-tabContent">
          {tabContents.map((content, index) => (
            <TabContent
              key={index}
              title={content.title}
              content={content.content}
              secSentence={content.secSentence || ""}
              secContent={content.secContent || ""}
              lists={content.lists || ""}
              target={content.target}
              addedLink={content.addedLink}
            />
          ))}
        </div>
      </div>

      <div className="text-center px-5">
        <hr />
        <a href="/industry">Go to Industries</a>
      </div>
    </div>
  );
};

export default IndustryNav;
