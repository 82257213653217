import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import MainUI from "../Inudstry-UI/IndustryMain";
import LGIcon from "../../../assets/Industry/InsideSalesAgent/LG.png";
import WCCIcon from "../../../assets/Industry/InsideSalesAgent/WCC.png";
import LSQIcon from "../../../assets/Industry/InsideSalesAgent/LSQ.png";
import CPIcon from "../../../assets/Industry/InsideSalesAgent/CP.png";
import LNIcon from "../../../assets/Industry/InsideSalesAgent/LN.png";
import REITIcon from "../../../assets/Industry/InsideSalesAgent/REIT.png";

const InsideSalesAgent = () => {
	const btns = [
		{
			target: "LG",
			label: "Lead Generation",
		},
		{
			target: "WCC",
			label: "Warm & Cold Calling",
		},
		{
			target: "LSQ",
			label: "Lead Screening and Qualification",
		},
		{
			target: "CP",
			label: "Client Prospecting",
		},
		{
			target: "LN",
			label: "Lead Nurturing",
		},
		{
			target: "REIT",
			label: "Real Estate ISA Tasks",
		},
	];

	const contents = [
		{
			target: "LG",
			icon: LGIcon,
			title: "Lead Generation",
			tasks: [
				"Find possible leads using a range of channels",
				"Build a database of leads with contact details and other useful information to assist you in setting priorities",
			],
		},
		{
			target: "WCC",
			icon: WCCIcon,
			title: "Warm & Cold Calling",
			tasks: [
				"Contact prospective customers using acquired and incoming leads",
				"To produce new leads, stay on top of outreach and follow-ups with former clients in your database",
				"Transform leads into fresh agreements within your sales funnel",
			],
		},
		{
			target: "LSQ",
			icon: LSQIcon,
			title: "Lead Screening and Qualification",
			tasks: [
				"Determine potential candidates for meetings",
				"Prior to a closing agent reaching out, pre-qualify leads by giving them a call to gather information",
				"Monitor qualified prospects in your database",
			],
		},
		{
			target: "CP",
			icon: CPIcon,
			title: "Client Prospecting",
			tasks: [
				"Create a prospect profile to aid in identifying the appropriate prospects",
				"Make a list of possible customers based on the characteristics of your ideal client",
				"Determine potential candidates for meetings",
			],
		},
		{
			target: "LN",
			icon: LNIcon,
			title: "Lead Nurturing",
			tasks: [
				"Make focused follow-up calls to prospective leads to boost engagement and advance them through the sales cycle",
				"Support automated content delivery to boost interaction",
				"Schedule time to speak with qualified leads",
			],
		},
		{
			target: "REIT",
			icon: REITIcon,
			title: "Real Estate ISA Tasks",
			tasks: [
				"Make an appointment by calling expired listings to discuss potential new sellers",
				"Make an effort to find more possible sellers by contacting the communities surrounding recently listed and sold homes",
				"Utilize the RedX/Vulcan7 system to make calls and tracking easier",
			],
		},
	];

	const paragraphs = [
		"Building a successful business requires time-consuming tasks like lead generation and prospecting, and business owners frequently discover that the busier they get handling deals and customer service, the more difficult it is to find time to prospect for new clients.",
		"Have you bought leads but been too busy to nurture and follow up with them? Or have you given them to your agents only to discover that they haven't made any contact at all with those leads?",
		"ISAs supplement your lead generation efforts by working remotely to take over your prospecting.",
		"Our skilled ISA can connect with leads and future customers, monitor and follow up with warm leads, screen, qualify, and track leads, assist with automated content delivery, make focused follow-up calls to boost engagement, and much more.",
		"Use the experienced ISA from Cyber Pros to make sure your pipeline is constantly full.",
	];
	return (
		<div>
			<Navbar />
			<MainUI
				title="Inside Sales Agent"
				ribbon="Work With An Inside Sales Agent (ISA) To Produce More Leads"
				cardTitle="What An Inside Sales Agent Virtual Professional Can Do For You"
				paragraphs={paragraphs}
				tabInit="LG"
				btns={btns}
				contents={contents}
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default InsideSalesAgent;
