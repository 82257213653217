import Footer from "../Footer/Footer";
import Navbar from "../Navbar";
import HireVA from "../Pricing/HireVA/HireVA";
import Banner from "./Inudstry-UI/Banner";
import Hero from "./Inudstry-UI/Hero";
import "./Inudstry-UI/industries.css";
import WeHelp from "./Inudstry-UI/WeHelp";
import WhyUs from "./Inudstry-UI/WhyUs";

const Industry = () => {
	return (
		<div id="industries">
			<Navbar />
			<Hero />
			<Banner />
			<HireVA />
			<WhyUs />
			<WeHelp />
			<Footer />
		</div>
	);
};

export default Industry;
