import React from "react";
import Navbar from "../components/Navbar";
import Hero2 from "../components/Hero/Hero2";
import Plan from "../components/Pricing/Plan/Plan";
import Outsource from "../components/Pricing/Outsource/Outsource";
import HireVA from "../components/Pricing/HireVA/HireVA";
import Footer from "../components/Footer/Footer";

const Pricing = () => {
  return (
    <div>
      <Navbar />
      <Hero2 />
      <Plan />
      <Outsource />
      <HireVA />
      <Footer />
    </div>
  );
};

export default Pricing;
