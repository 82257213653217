import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import MainUI from "../Inudstry-UI/IndustryMain";

const EngineeringSupport = () => {
	const paragraphs = [
		"An independent specialist with a focus on providing operational and administrative support to engineering firms is known as a virtual engineering professional.",
		"The goal of engineering support is to ensure the successful completion and operation of engineering projects by addressing challenges and optimizing processes.",
	];

	const asIs = [
		"STRUCTURAL DETAILING",
		"QUANTITY SURVEY (QS)",
		"BUILDING INFORMATION MODELING (BIM)",
		"ENERGY MODELING",
	];
	return (
		<div>
			<Navbar />
			<MainUI
				title="Engineering Support"
				ribbon="Boost Efficiency with a Virtual Professional Transaction Coordinator (TC)"
				cardTitle="What We Can Do for You:"
				paragraphs={paragraphs}
				asIs={asIs}
				addedclassName="ms-5"
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default EngineeringSupport;
