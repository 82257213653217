import React from "react";

const Hero = () => {
  return (
    <div className="hero-industry">
      <div className="container">
        <div className="w-50">
          <h3 className="fw-semibold text-light my-0 ">HIRE US</h3>
          <h1 className="fw-bold" style={{ fontSize: "4.2rem" }}>
            A <span className="text-info">TRUSTWORTHY</span> AND
            <span className="text-info"> EXPERT</span> ON THE FIELD
          </h1>
          <p>
            Learn more about the key industries where our Virtual Professional
            are making a significant impact, and discover why so many choose
            Cyber Pros!
          </p>
          <a href="/book" className="btn ms-3">
            Book a Strategy Call
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
