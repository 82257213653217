import React from "react";
import "./PrivacyPolicy2.css";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar";

const PrivacyPolicy2 = () => {
  return (
    <div>
      <Navbar /> {/* Add the Navbar at the top */}
      <div className="container my-5">
        <h1>Statement of Privacy</h1>
        <p>
          This Privacy Statement aims to enlighten you about the kinds of
          personal data we might gather about you when you visit our website,
          how we might use that data, and whether or not we might share that
          data with third parties.
        </p>
        <h2>What Data We Gather and How We Apply It</h2>
        <p>
          Generally speaking, all of the data we gather on our site is
          classified as personally identifiable information. Information that
          specifically identifies you is known as personally identifiable
          information. Generally speaking, you are free to browse our site
          without providing any personally identifiable information or
          identifying yourself to us.
        </p>
        <h2>Disclaimer of Information</h2>
        <p>
          We do not sell, trade, rent, or otherwise retransmit any Personally
          Identifiable Information we collect online without your consent, with
          the exception of what is stated in this Privacy Statement. Your
          personally identifiable information will be used for your purchases
          and kept in our database system, which creates our invoices, as well
          as on hard copy copies of the original invoices.
        </p>
        <p>
          We might occasionally be asked to produce Personally Identifiable
          Information in response to an order, court order, or government probe.
          Additionally, we reserve the right to notify law enforcement of any
          actions that we honestly believe to be illegal. When we feel that
          disclosing Personally Identifiable Information is reasonably required
          to uphold or apply our Limited Warranty or to safeguard our own safety
          as well as the rights, property, and interests of others, we may do
          so.
        </p>
        <h2>Links Going After This Page</h2>
        <p>
          Links to websites operated by third parties might be offered purely
          for your convenience and informational purposes, or to give you more
          details about a range of other products and services. You will exit
          our site if you click on these links. We have no control over the
          content or privacy policies of the websites we link to from this site,
          and this privacy statement does not extend to their information
          practices. We advise you to carefully read through the privacy
          statements on every website you visit.
        </p>
        <h2>Modifications to This Statement</h2>
        <p>
          Any modifications or additions to the terms of this privacy statement
          will be announced on our website, with the most recent version's date
          shown below. In particular, before submitting any Personally
          Identifiable Information at our site, please check back frequently to
          see if this Privacy Statement has changed.
        </p>
        <p>
          By accessing our website, you agree to the privacy statement that is
          in force at the time of use.
        </p>
      </div>
      <Footer /> {/* Add the Footer at the bottom */}
    </div>
  );
};

export default PrivacyPolicy2;
