import React from "react";
import "./Advantage.css";
import Hire from "../../assets/Icons/hire.png";
import Growth from "../../assets/Icons/growth.png";
import Standard from "../../assets/Icons/standard.png";
import Pricing from "../../assets/Icons/pricing.png";

const AdvLists = ({ title, text, icon }) => (
  <div className="col-lg-3 col-md-6 px-3 mt-5 text-center">
    <div
      style={{ backgroundColor: "#33c2", height: "70px", width: "70px" }}
      className="d-flex align-items-center justify-content-center rounded-circle mx-auto"
    >
      <img src={icon} alt={title} className="mb-0" height={40} />
    </div>
    <h5 className="card-title my-0 my-3">{title}</h5>
    <p className="card-text my-0">{text}</p>
  </div>
);

const Advantage = () => {
  const adv = [
    {
      icon: Hire,
      title: "Speed to Hire",
      text: "We minimize your hiring time, getting you the talent you need quickly.",
    },
    {
      icon: Growth,
      title: "Focus on Growth",
      text: "Your success is our priority. We partner with you to fuel your business expansion.",
    },
    {
      icon: Standard,
      title: "Standardized Work Environment",
      text: "Streamlined processes to secure efficiency and consistency in delivering results.",
    },
    {
      icon: Pricing,
      title: "Inclusive Pricing",
      text: "Transparent and accessible pricing models that fit your budget.",
    },
  ];

  return (
    <section id="advantage" className="py-5">
      <div className="container text-center">
        <h2 className="fw-bold mb-4">What Set Us Apart?</h2>
        <div className="row">
          {adv.map((ad, index) => (
            <AdvLists
              key={index}
              title={ad.title}
              text={ad.text}
              icon={ad.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Advantage;
