import React from "react";
import { NavLink } from "react-router-dom";
import "./Hero2.css";

const Hero2 = () => {
  return (
    <section id="hero2" className="d-flex align-items-center text-white">
      <div className="container">
        <div className="row">
          {/* Content column */}
          <div className="col-lg-12 col-md-12 mb-4 mb-md-0">
            <h1 className="display-4">Here in Cyber Pros, we offer</h1>
            <h2 className="display-3">GREAT SERVICES</h2>
            <p className="lead">
              Our commitment to excellence delivers top-tier service, freeing
              you to focus on core business goals.
            </p>
            <NavLink
              to="/book"
              className="btn btn-primary fw-bold rounded-pill px-5"
            >
              Book a strategy call
            </NavLink>
          </div>
          {/* Empty column for layout purposes */}
          <div className="col-lg-5 d-none d-lg-block"></div>
        </div>
      </div>
    </section>
  );
};

export default Hero2;
