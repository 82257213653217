import React from "react";
import { NavLink } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  return (
    <section id="hero" className="d-flex align-items-center py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 text-md-start  text-center">
            <h1 className="display-4">Grow your Business with Cyber Pros</h1>
            <h2 className="my-3">VIRTUAL PROFESSIONAL</h2>
            <p className="lead">
              By dedicating ourselves to excellence, we ensure you receive
              premium service, freeing you to achieve your core business goals
              while we manage operational complexities
            </p>
            <NavLink to="/book" className="btn btn-primary rounded-pill px-5">
              Book a strategy call
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
