import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer/Footer";
import Questions from "../components/FAQs/Questions";

const FAQs = () => {
	return (
		<div>
			<Navbar />
			<Questions />
			<Footer />
		</div>
	);
};

export default FAQs;
