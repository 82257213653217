import React from "react";
import "./Looking.css";
import { NavLink } from "react-router-dom";
import HR from "../../../assets/Services/icon/hr.png";
import CS from "../../../assets/Services/icon/custServ.png";
import PM from "../../../assets/Icons/admin.png";
import TS from "../../../assets/Services/icon/techSupp.png";
import DE from "../../../assets/Services/icon/dataEntry.png";
import WD from "../../../assets/Services/icon/webDev.png";

const services = [
	{
		icon: HR,
		title: "Human Resource",
		text: "Manage recruitment, employee relations, and HR functions to support a productive workspace.",
	},
	{
		icon: CS,
		title: "Customer Service",
		text: "Provide exceptional assistance and support to customers through various communication channels.",
	},
	{
		icon: PM,
		title: "Project Manager",
		text: "Lead and oversee projects to ensure timely and successful completion within budget.",
	},
	{
		icon: TS,
		title: "Technical Support",
		text: "Offer technical assistance and troubleshoot issues to maintain client satisfaction and service continuity.",
	},
	{
		icon: DE,
		title: "Data-Entry Clerk",
		text: "Accurately enter and update data in your systems to ensure information integrity.",
	},
	{
		icon: WD,
		title: "Web Developer",
		text: "Design, develop, and maintain websites and web applications to enhance your digital presence.",
	},
];

const ServiceCard = ({ title, text, icon }) => (
	<div className="col-md-4">
		<div className="card text-center">
			<div className="card-body">
				<img src={icon} alt={title} />
				<h5 className="card-title mt-3">{title}</h5>
				<p className="card-text">{text}</p>
			</div>
		</div>
	</div>
);

const Looking = () => {
	return (
		<section
			id="lookingsection"
			className="py-5"
			style={{ fontFamily: "Inter, sans-serif" }}
		>
			<div className="container">
				<h2 className="text-center">
					WE ARE <span className="text-primary">LOOKING</span>
				</h2>
				<div className="row mt-4">
					{services.map((service, index) => (
						<ServiceCard
							key={index}
							icon={service.icon}
							title={service.title}
							text={service.text}
						/>
					))}
				</div>
				<div className="text-center btn-text mt-4">
					<p className="mx-auto">
						ARE YOU READY FOR YOUR NEXT CAREER?
					</p>
					<NavLink to="/ph/apply" className="btn rounded-pill px-3">
						Apply Now!
					</NavLink>
				</div>
			</div>
		</section>
	);
};

export default Looking;
