import React from "react";
import Navbar from "../../Navbar-Phase1/Navbar";
import Footer from "../../Footer/Footer";
import Main from "./ui/Main";
import "./Blog1.css";

const Blog1 = () => {
	return (
		<div id="blog1">
			<Navbar />
			<Main />
			<Footer />
		</div>
	);
};

export default Blog1;
