import React from "react";
import { NavLink } from "react-router-dom";
import "./What1.css";

const What1 = () => (
  <section id="services" className="py-5">
    <div className="container">
      <h2 className="text-center">
        WHAT ARE YOU <span className="text-primary">WAITING</span> FOR?
      </h2>

      <div className="text-center btn-text mt-4">
        <p className="mx-auto">
          By choosing Cyber Pros, you are positioning your business at the
          forefront of industry innovation
        </p>
        <NavLink to="/book" className="btn rounded-pill">
          Book a strategy call
        </NavLink>
      </div>
    </div>
  </section>
);

export default What1;
