import React from "react";
import Navbar from "../../Navbar-Phase1/Navbar";
import Footer from "../../Footer/Footer";
import Main2 from "./ui/Main2";

const Blog2 = () => {
	return (
		<div id="blog2">
			<Navbar />
			<Main2 />
			<Footer />
		</div>
	);
};

export default Blog2;
