import React from "react";
import "./Outsource.css";
import p1 from "../../../assets/Pricing/Icons/1.png";
import p2 from "../../../assets/Pricing/Icons/2.png";
import p3 from "../../../assets/Pricing/Icons/3.png";
import p4 from "../../../assets/Pricing/Icons/4.png";
import p5 from "../../../assets/Pricing/Icons/5.png";
import p6 from "../../../assets/Pricing/Icons/6.png";
import p7 from "../../../assets/Pricing/Icons/7.png";
import p8 from "../../../assets/Pricing/Icons/8.png";
import p9 from "../../../assets/Pricing/Icons/9.png";

const outsource = [
  {
    icon: p1,
    title: "Loan Processor",
    text: "Ensure the accuracy and completeness of borrower information, review credit reports, and coordinate with underwriters to facilitate the approval process.",
  },
  {
    icon: p2,
    title: "Net Developer",
    text: "Analyze network performance data and identify areas for improvement. Optimize network configurations to enhance speed, reliability, and efficiency.",
    income: "$9-25 / hour",
  },
  {
    icon: p3,
    title: "Graphic Designer",
    text: "Develop creative concepts and design ideas for various projects, including marketing materials, websites, and branding. Collaborate with clients or team members to understand project requirements and objectives.",
  },
  {
    icon: p4,
    title: "Executive Assistant",
    text: "Assist with the preparation of reports, presentations, and other documents, while maintaining confidentiality and exercising discretion.",
  },
  {
    icon: p5,
    title: "Book Keeper",
    text: "Maintain and update financial records, including ledgers, invoices, receipts, and bank statements. Ensure all financial transactions are accurately recorded and categorized.",
  },
  {
    icon: p6,
    title: "SEO Specialist",
    text: "Conduct keyword research to identify relevant search terms and phrases for targeting. Analyze keyword competitiveness and search volume to optimize content.",
  },
  {
    icon: p7,
    title: "Virtual Professional",
    text: "Hiring a virtual professional can be more cost-effective than a full-time in-office assistant, saving on overhead costs such as office space and equipment.",
  },
  {
    icon: p8,
    title: "Data Entry Specialist",
    text: "Accurately enter and update data into databases, spreadsheets, and other digital systems. Ensure the integrity and accuracy of the data being entered.",
  },
  {
    icon: p9,
    title: "Recruitment Specialist",
    text: "Source candidates through various channels, including job boards, social media, networking, and referrals.",
  },
];

const OutsourceCard = ({ title, text, icon, income }) => (
  <div className="col-12 col-md-6 col-lg-4 mb-4 d-flex align-items-start">
    <div className="d-flex flex-column align-items-center">
      <img src={icon} alt={title} className="osIcon mb-3" />
      <div className="osTexts text-center text-light">
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  </div>
);

const Outsource = () => {
  return (
    <section id="outsource" className="py-5">
      <div className="container text-center">
        <h1 className="text-light mb-4">
          What are you looking to <span>OUTSOURCE</span> ?
        </h1>
        <div className="row">
          {outsource.map((os, index) => (
            <OutsourceCard
              key={index}
              icon={os.icon}
              title={os.title}
              text={os.text}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Outsource;
