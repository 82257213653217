import React, { useState } from "react";
import LogoWName from "../../assets/cplogo.png";
import "./Footer.css";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
  FaPaperPlane,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Component for individual contact info
const ContactInfo = ({ icon, text }) => (
  <div className="contact-info d-flex flex-row">
    <span className="contactIcon">{icon}</span>
    <p>{text}</p>
  </div>
);

// Contact information data
const ContactInfos = [
  {
    icon: <FaMapMarkerAlt />,
    text: "Unit 218 Civic Prime, Civic Drive Filinvest Corporate City, Alabang, Muntinlupa City 1781",
  },
  {
    icon: <FaEnvelope />,
    text: "jobs@cyberpros.ph",
  },
  {
    icon: <FaPhone />,
    text: "+639455617642",
  },
];

// Navigation items data
const navItems = [
  {
    label: "Home",
    href: "/ph/home",
  },
  {
    label: "About",
    href: "/ph/about",
  },
  {
    label: "Career",
    href: "/ph/career",
  },
  {
    label: "Apply Now",
    href: "/ph/apply",
  },
  {
    label: "Privacy Policy",
    href: "/ph/privacy-policy", // Updated link to the new Privacy Policy page
  },
];

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://cyberpros.ph/api/contactUs.php",
        formData
      );

      if (response.data.status === "success") {
        toast.success("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        toast.error(
          response.data.message || "Error sending message! Please try again."
        );
      }
    } catch (error) {
      toast.error("Error sending message! Please try again.");
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <footer className="bg-dark text-white py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <h5>GET IN TOUCH</h5>
              <p>Our Contact Details:</p>
              <p className="lil-text">
                Come join our team! We would love to hear from you.
              </p>
              <div className="d-flex flex-column mt-4">
                {ContactInfos.map((info, index) => (
                  <ContactInfo key={index} icon={info.icon} text={info.text} />
                ))}
              </div>

              <div className="navi mt-4">
                <ul className="list-unstyled">
                  {navItems.map((item, index) => (
                    <li key={index}>
                      <NavLink to={item.href} className="nav-link">
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <h5>Contact Us</h5>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="name">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="email">
                    Email:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="message">
                    Message:
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn angas px-3 rounded-pill mt-4"
                >
                  <FaPaperPlane className="text-light p-0 m-0 me-2" />
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <div className="last container d-flex flex-column flex-md-row justify-content-between align-items-center my-3">
        <img src={LogoWName} alt="CyberPros Logo" height={60} />
        <p className="mb-0">&copy; 2024 Cyber Pros. All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;
