import React from "react";
import "./Book.css";
import { NavLink } from "react-router-dom";

const Book = () => {
  return (
    <section id="book">
      <div className="container">
        <div className="content">
          <h1>READY UPSCALE YOUR BUSINESS WITH US?</h1>
          <p>
            Hire a Cyber Pros Virtual Professional and cut costs by 60%. TODAY.
          </p>
          <NavLink to={"/book"}>
            <div className="btn btn-lg bookBtn rounded-pill px-5">
              Book a Strategy Call
            </div>
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default Book;
