import React from "react";
import Focus from "../../../assets/Indus/Focus.png";
import Balance from "../../../assets/Indus/Balance.png";
import Savings from "../../../assets/Indus/Savings.png";

const WhyUs = () => {
  const included = [
    "VP Health Benefits & Insurance",
    "VP Background checks (FBI grade)",
    "Schooled + Work Background",
    "Training Materials & Talent Onboarding",
    "Two-Pronged Approval Process",
    "Philippine Legal Entity",
    "VP DISC Profiling",
    "Secure Payments that Comply with PCI",
    "Uniform office and equipment guidelines",
    "Software for Tracking Productivity",
  ];

  const additional = ["Human resource concerns", "Philippine Social Benefits"];
  return (
    <div className="whyUs text-center my-5">
      <div className="col-6 mx-auto">
        <div className="card border-dark border-2 p-5">
          <h1>
            WHY <span className="fw-bold text-primary">CYBER PROS</span>?
          </h1>
          <p>
            Well qualified, experienced, and dedicated virtual professionals
            within the Philippines.
          </p>

          <h5 className="mt-3 fw-bold">
            WHAT ARE{" "}
            <span className="text-primary">
              THE ADVANTAGES OF HIRING A VIRTUAL PROFESSIONAL
            </span>
            ?<br />
            HERE ARE A FEW BENEFITS THAT OUR CLIENTS ARE EXTREMELY SATISFIED
            WITH:
          </h5>

          <div className="icons m-5">
            <div className="d-flex align-items-center">
              <div>
                <div className="p-5 rounded-5 shadow">
                  <img src={Focus} alt="Focus" style={{ height: "3rem" }} />
                </div>
                <h5 className="fw-bold mt-3">FOCUS</h5>
              </div>
              <p className="fs-5 text-start ms-3">
                The fact that they are devoting more time to their essential
                tasks within the company brings them joy. They are reducing the
                time spent on tasks that could be handled by others.
              </p>
            </div>

            <div className="d-flex align-items-center">
              <p className="fs-5 text-end me-3">
                They experience peace of mind and work-life balance, including
                going to the gym, wrapping up work at a reasonable time,
                spending time with family, and having time for business
                planning.
              </p>
              <div>
                <div className="p-5 rounded-5 shadow">
                  <img src={Balance} alt="Balance" style={{ height: "3rem" }} />
                </div>
                <h5 className="fw-bold mt-3">BALANCE</h5>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div>
                <div className="p-5 rounded-5 shadow">
                  <img src={Savings} alt="Savings" style={{ height: "3rem" }} />
                </div>
                <h5 className="fw-bold mt-3">SAVINGS</h5>
              </div>
              <p className="fs-5 text-start ms-3">
                Saving money is achieved by paying for the hours worked instead
                of hiring an in-house employee or doing the work oneself.
              </p>
            </div>
          </div>

          <p className="w-75 mx-auto fw-semibold">
            Save up the expenses of a full-time employee with{" "}
            <span className="text-primary">
              Cyber Pros Virtual Professionals
            </span>{" "}
            - no need to worry about insurance, retirement, office and equipment
            costs, and unemployment.
          </p>

          <div className="col-9 text-start mx-auto mt-5">
            <h6
              className="mb-2 fw-bold"
              style={{ color: "var(--main-color-hover)" }}
            >
              Included
            </h6>
            <ul>
              {included.map((inc, index) => (
                <li key={index}>{inc}</li>
              ))}
            </ul>

            <h6
              className="mt-4 mb-2 fw-bold"
              style={{ color: "var(--main-color-hover)" }}
            >
              In addition to paying our virtual professional competitively, we
              also take care of:
            </h6>
            <ul>
              {additional.map((add, index) => (
                <li key={index}>{add}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
