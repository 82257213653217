import React from "react";
import "./Success.css";

const Success = ({ successRef }) => {
  return (
    <section
      id="success"
      ref={successRef}
      className="py-5"
      style={{ fontFamily: "Inter, sans-serif" }}
    >
      <div className="container">
        <h1 className="text-center" style={{ fontWeight: 700 }}>
          WHAT WE <span style={{ color: "#31b6dd" }}>DO</span>
        </h1>

        <h1 className="text-center">
          Guaranteed To Provide You
          <span style={{ fontWeight: 700 }}> Success </span>
          and
          <span style={{ fontWeight: 700 }}> Confidence</span>
        </h1>

        <div className="text-center btn-text mt-4">
          <p className="mx-auto">
            By choosing Cyber Pros, you are positioning your career at the
            forefront of industry innovation
          </p>
        </div>
      </div>
    </section>
  );
};

export default Success;
