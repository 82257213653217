import React from "react";
import { useNavigate, NavLink } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ background: "#ccc" }}
      className="vh-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div className="d-flex text-secondary fs-3 mb-0 pt-0">
        <p className="border-end border-secondary pe-3">404</p>
        <p className="ps-3">Not Found</p>
      </div>

      <NavLink
        onClick={() => navigate(-1)} // go back to previous page
        style={{ fontSize: "14px", opacity: 0.6 }}
        className="mt-0 pt-0"
      >
        Go back
      </NavLink>
    </div>
  );
};

export default NotFound;
