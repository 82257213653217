import React from "react";
import CompanyImg from "../../../assets/About/section 1 overlay.png";
import "./CompanyMV.css";

const CompanyMV = () => {
  return (
    <section id="companyMV" className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <img
              src={CompanyImg}
              alt="Grow your Business"
              className="img-fluid company-img"
            />
          </div>
          <div className="col-lg-6">
            <div className="text-light px-3">
              <h2 className="big-text">Company's Mission & Vision</h2>
              <p className="lil-text">
                "Our mission is to provide exceptional business process
                outsourcing services that enhance the efficiency and
                productivity of our clients. We are committed to delivering
                high-quality solutions through innovation, integrity, and a
                customer-centric approach, ensuring the success of our partners
                and the growth of our employees."
              </p>
              <p className="lil-text">
                "Our vision is to be the global leader in business process
                outsourcing, recognized for our unparalleled service, innovative
                solutions, and dedicated workforce. We aim to set the industry
                standard for excellence, creating value for our clients and
                opportunities for our employees while contributing to the
                broader community and fostering sustainable growth."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyMV;
