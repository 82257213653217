import React, { useRef } from "react";

import Footer from "../components/Footer/Footer";
import WelcomeAbout from "../components/About_Components/Home-Section/WelcomeAbout";
import Time from "../components/About_Components/Time/Time";
import Success from "../components/About_Components/Success/Success";
import Success1 from "../components/About_Components/Success/Success1";
// import Team from "../components/About_Components/Team/Team";
import What1 from "../components/Phase1_Components/What/What1";
import Navbar from "../components/Navbar";
import BePart from "../components/BePart/BePart";

const About = () => {
  const successRef = useRef(null);

  const scrollSuccess = () => {
    successRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Navbar />
      <WelcomeAbout scrollSuccess={scrollSuccess} />
      <Time />
      <Success successRef={successRef} />
      <Success1 />
      <BePart />
      {/* <Team /> */}
      <What1 />
      <Footer />
    </div>
  );
};

export default About;
