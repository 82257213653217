import React from "react";
import "./Success.css";

const Success = ({ successRef }) => {
  return (
    <section
      id="success"
      ref={successRef}
      className="py-5"
      style={{ fontFamily: "Inter, sans-serif" }}
    >
      <div className="container">
        <h1 className="text-center" style={{ fontWeight: 700 }}>
          WHAT WE <span style={{ color: "#31b6dd" }}>DO</span>
        </h1>

        <div />
        <div className="text-center ">
          At Cyber Pros, we offer top tier virtual professionals across various
          roles including customer support, IT support, inside sales,
          telemarketing, administrative assistance, engineering support and
          more. Our team is equipped to handle your back-end administrative
          tasks, helping you streamline operations and reduce costs, so you can
          concentrate on what you do best --- growing your business!
          <br />
          <br />
          Choose Cyber Pros for cost- effective, scalable solutions in a
          customer centric focus. Our service goes beyond simply providing
          virtual professionals tailored to your needs – we also offer
          continuous support to ensure your virtual team keeps pace with your
          company’s growth. Leveraging advanced technology and industry
          expertise, we customize our services to meet your unique requirements
          and drive your success. Contact us today to discover how we can help
          fuel your business growth.
        </div>
      </div>
    </section>
  );
};

export default Success;
