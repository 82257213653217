import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import AdminUI from "../Inudstry-UI/IndustryMain";
import SAPIcon from "../../../assets/Industry/ITVP/SAP.png";
import HAIcon from "../../../assets/Industry/ITVP/HA.png";
import TNIIcon from "../../../assets/Industry/ITVP/TNI.png";
import STIcon from "../../../assets/Industry/ITVP/ST.png";
import SSIcon from "../../../assets/Industry/ITVP/SS.png";
import RPRIcon from "../../../assets/Industry/ITVP/RPR.png";
import WASIcon from "../../../assets/Industry/ITVP/WAS.png";
import RBMIcon from "../../../assets/Industry/ITVP/RBM.png";
import SMIcon from "../../../assets/Industry/ITVP/SM.png";
import HSIcon from "../../../assets/Industry/ITVP/HS.png";
import DRSIcon from "../../../assets/Industry/ITVP/DRS.png";
import IAMSIcon from "../../../assets/Industry/ITVP/IAMS.png";
import DSSIcon from "../../../assets/Industry/ITVP/DSS.png";

const ITSupport = () => {
  const btns = [
    {
      target: "SAP",
      label: "Software Assistance Professional",
    },
    {
      target: "HA",
      label: "Hardware Assistance",
    },
    {
      target: "TNI",
      label: "Troubleshooting Network Issues",
    },
    {
      target: "ST",
      label: "System Troubleshooting",
    },
    {
      target: "SS",
      label: "Sales Support",
    },
    {
      target: "RPR",
      label: "Remote PC Repair",
    },
    {
      target: "WAS",
      label: "Warranty Assistance and Support",
    },
    {
      target: "RBM",
      label: "Remote Backup Management",
    },
    {
      target: "SM",
      label: "Security Management",
    },
    {
      target: "HS",
      label: "Helpdesk Support",
    },
    {
      target: "DRS",
      label: "Disaster Recovery Services",
    },
    {
      target: "IAMS",
      label: "IT Asset Management Services",
    },
    {
      target: "DSS",
      label: "DBA Support Services",
    },
  ];

  const contents = [
    {
      target: "SAP",
      icon: SAPIcon,
      title: "Software Assistance Professional",
      tasks: [
        "Offers professional software support and post-purchase assistance for your software products.",
        "Our virtual professional software assistance services include fixing software-related issues like driver installations, software updates, and other issues, as well as maintaining and supporting software",
      ],
    },
    {
      target: "HA",
      icon: HAIcon,
      title: "Hardware Assistance",
      tasks: [
        "Professional hardware assistance support is offered by our experts, and it may be tailored to your needs. Any and all computer hardware issues can be fixed with the assistance of our VPs, who are always available",
      ],
    },
    {
      target: "TNI",
      icon: TNIIcon,
      title: "Troubleshooting Network Issues",
      tasks: [
        "Our remote experts are skilled and qualified to offer prompt troubleshooting for network difficulties, such as those involving internet access and network administration. In order to speed up the resolution of network  problems, our VPs can offer ongoing remote network monitoring.",
      ],
    },
    {
      target: "ST",
      icon: STIcon,
      title: "System Troubleshooting",
      tasks: [
        "Our virtual professionals are capable of actively debugging and offering solutions to remedy problems with the system. Additionally capable of proactive network and system monitoring and management are our VPs.",
      ],
    },
    {
      target: "SS",
      icon: SSIcon,
      title: "Sales Support",
      tasks: [
        "For a range of goods and services, our VPs can offer professional pre- and post-sale product support. No matter what kind of sales support you require, our VPs can get to work right away to give you the help you require.",
      ],
    },
    {
      target: "RPR",
      icon: RPRIcon,
      title: "Remote PC Repair",
      tasks: [
        "Our VP’s have extensive backgrounds in effectively and dependably assisting clients with PC repairs. You may be certain that our VPs will take care of it if it can be fixed remotely.",
      ],
    },
    {
      target: "WAS",
      icon: WASIcon,
      title: "Warranty Assistance and Support",
      tasks: [
        "Our VPs can provide a full range of services related to warranty support.",
      ],
    },
    {
      target: "RBM",
      icon: RBMIcon,
      title: "Remote Backup Management",
      tasks: [
        "Our VPs are adept at providing remote backup management services to help customers with all their backup needs.",
      ],
    },
    {
      target: "SM",
      icon: SMIcon,
      title: "Security Management",
      tasks: [
        "Expert security management services, such as real-time bug fixes, email troubleshooting, compliance with security protocols, and virus removal and healing, are offered by our VPs.",
      ],
    },
    {
      target: "HS",
      icon: HSIcon,
      title: "Helpdesk Support",
      tasks: [
        "A variety of expert helpdesk support services, including corporate helpdesk support, can be dependably obtained from our VPs.",
      ],
    },
    {
      target: "DRS",
      icon: DRSIcon,
      title: "Disaster Recovery Services",
      tasks: [
        "In the case of a system breakdown, our VPs offer a wide range of disaster recovery services, including data recovery to enable effective business continuity.",
      ],
    },
    {
      target: "IAMS",
      icon: IAMSIcon,
      title: "IT Asset Management Services",
      tasks: [
        "Our VPs offer clients worldwide professional IT asset management services.",
      ],
    },
    {
      target: "DSS",
      icon: DSSIcon,
      title: "DBA Support Services",
      tasks: [
        "Remote DBA support services, including crucial data management and protection, are offered by our VPs.",
      ],
    },
  ];

  const paragraphs = [
    "IT is the way of the future for businesses in the service sector. These experts, who have a focus on computer programs and procedures, assist companies in creating leads and streamlining internal operations by carrying out the following duties.",
    "Our IT outsourcing and tech virtual professionals at Cyber Pros can assist you with a variety of tasks, including technical support, web design, and systems management. Every professional on our roster possesses a high level of training and skill. This implies that you may relax knowing that they will only deliver top-notch work on schedule and in accordance with your requirements.",
  ];
  return (
    <div>
      <Navbar />
      <AdminUI
        tabInit="SAP"
        title="Information Technology (IT VP) Support"
        ribbon="Boost Efficiency with a Information Technology (ITVP) Support"
        paragraphs={paragraphs}
        cardTitle="What a Virtual Professional in IT Can Do for You:"
        btns={btns}
        contents={contents}
      />
      <Book />
      <Footer />
    </div>
  );
};

export default ITSupport;
