import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import MainUI from "../Inudstry-UI/IndustryMain";
import LSQIcon from "../../../assets/Industry/SalesDev/LSQ.png";
import PCFUIcon from "../../../assets/Industry/SalesDev/PCFU.png";
import LNIcon from "../../../assets/Industry/SalesDev/LN.png";
import WCCIcon from "../../../assets/Industry/SalesDev/WCC.png";

const SalesDevelopment = () => {
	const btns = [
		{
			target: "LSQ",
			label: "Lead Screening and Qualification",
		},
		{
			target: "PCFU",
			label: "Prospect and/or Client Follow Up",
		},
		{
			target: "LN",
			label: "Lead Nurturing",
		},
		{
			target: "WCC",
			label: "Warm and Cold Calling",
		},
	];

	const contents = [
		{
			target: "LSQ",
			icon: LSQIcon,
			title: "Lead Screening and Qualification",
			tasks: [
				"Observing your qualification checklist and script(s)",
				"Determine and evaluate qualified leads and prospects to schedule meetings.",
			],
		},
		{
			target: "PCFU",
			icon: PCFUIcon,
			title: "Prospect and/or Client Follow Up",
			tasks: [
				"Stay in touch through text, phone, and email correspondence.",
				"Maintain and update your CRM and database.",
			],
		},
		{
			target: "LN",
			icon: LNIcon,
			title: "Lead Nurturing",
			tasks: [
				"Monitor qualified leads and assist with content delivery that is automated",
				"Focused follow-up calls to boost participation",
				"Talk about how fast you and your group can reply to leads that are submitted",
			],
		},
		{
			target: "WCC",
			icon: WCCIcon,
			title: "Warm and Cold Calling",
			tasks: [
				"Contact prospective customers using acquired and incoming leads",
				"Prospecting in circles and geofarming",
				"Following up with former customers and your database in order to create new leads and close deals",
			],
		},
	];

	const paragraphs = [
		"Remember how important sales development is. You lose valuable time for both yourself and your sales team if you ignore it. One possible solution is to hire a SDR. They take care of lead generation, follow up, qualify leads, nurture prospects, and answer questions. Cyber Pros SDRs make sure you capture every business opportunity and produce quality leads. Time-consuming activities like lead qualification and prospecting can divert your attention from customers who are prepared to close deals. You can delegate these tasks to Cyber Pros SDRs and keep your lead pipeline consistent.",
		"Employ a Cyber Pros SDR to improve your lead generation and prospecting so you can concentrate on closing deals and boosting sales. Our SDRs put Speed-to-Lead first, making sure that incoming leads are pre-qualified and receive prompt responses. They also help with follow-up calls, lead nurturing, and other tasks.",
	];
	return (
		<div>
			<Navbar />
			<MainUI
				title="Sales Development"
				ribbon="Work with a Sales Development Representative (SDR) to generate more qualified leads."
				cardTitle="What An Sales Development Virtual Professional Can Do For You"
				paragraphs={paragraphs}
				tabInit="LSQ"
				btns={btns}
				contents={contents}
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default SalesDevelopment;
