import React from "react";
import Navbar from "../components/Navbar";
import Hero1 from "../components/Hero/Hero1";
import Footer from "../components/Footer/Footer";
import Roles from "../components/Roles/Roles";
import Grow1 from "../components/Grow/Grow1";
import Advantage from "../components/Advantage/Advantage";
// import Testimonials from "../components/Testimonials/Testimonials";
import Book from "../components/Book/Book";

const Services = () => {
	return (
		<div id="roles">
			<Navbar />
			<Hero1 />
			<Roles />
			<Grow1 />
			<Advantage />
			{/* <Testimonials /> */}
			<Book />
			<Footer />
		</div>
	);
};

export default Services;
