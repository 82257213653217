import React from "react";
import "./Hero1.css";

const Hero1 = () => {
  return (
    <section id="hero1">
      <div className="container text-center">
        <div>
          <h2>
            We Have Created <span>Professional Service</span> For Our Clients
          </h2>
          <p>
            Explore your Future with Us- Join a Team that Champions Excellence,
            Innovation and Collaboration
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero1;
