import React from "react";
import Navbar from "../../Navbar-Phase1/Navbar";
import Footer from "../../Footer/Footer";
import Main3 from "./ui/Main3";

const Blog3 = () => {
	return (
		<div id="blog3">
			<Navbar />
			<Main3 />
			<Footer />
		</div>
	);
};

export default Blog3;
