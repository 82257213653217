import React from "react";
import Navbar from "../components/Navbar";
import Hero3 from "../components/Hero/Hero3";
import Expect from "../components/Expect/Expect";
import Footer from "../components/Footer/Footer";

const StratCall = () => {
	return (
		<div>
			<Navbar />
			<Hero3 />
			<Expect />
			<Footer />
		</div>
	);
};

export default StratCall;
