import React from "react";
import "./WelcomeCareer.css";
const WelcomeCareer = () => {
  return (
    <section id="welcome_career">
      <div
        className="container text-center"
        style={{ fontFamily: "Inter, sans-serif" }}>
        <div className="col-12">
          <h1>
            WELCOME TO{" "}
            <span style={{ color: "#2EDBE2", fontWeight: 800 }}>
              CYBER PROS{" "}
            </span>
            CAREERS!
          </h1>
          <h2>
            Discover Your Future with Us - Join a Team that Values Excellence,
            Innovation, and Collaboration.
          </h2>
        </div>
      </div>
    </section>
  );
};

export default WelcomeCareer;
