import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";

const TabPane = ({ tabInit, btns, contents }) => {
	const [activeTab, setActiveTab] = useState(tabInit);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	return (
		<div className="row my-3">
			<div className="col-3">
				<div
					className="nav flex-column nav-pills"
					role="tablist"
					aria-orientation="vertical"
				>
					{btns.map((btn, index) => (
						<button
							key={index}
							className={`nav-btnx ${
								activeTab === btn.target ? "active" : ""
							}`}
							onClick={() => handleTabClick(btn.target)}
						>
							{btn.label}
						</button>
					))}
				</div>
			</div>

			<div className="col-9">
				<div className="tab-content">
					{contents.map((cont, index) => (
						<div
							key={index}
							className={`tab-pane fade ${
								activeTab === cont.target ? "show active" : ""
							}`}
						>
							<div className="d-flex align-items-center ms-3">
								<img
									src={cont.icon}
									alt={cont.title}
									height={40}
								/>{" "}
								<h4 className="fw-bold ms-3 pt-2">
									{cont.title}
								</h4>
							</div>
							<hr />
							<div className="ms-4">
								{cont.tasks.map((task, taskIndex) => (
									<div
										className="d-flex align-items-baseline"
										key={taskIndex}
									>
										<p className="text-warning">
											<FaCheck />
										</p>
										<p className="my-1 ms-3">{task}</p>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default TabPane;
