import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import MainUI from "../Inudstry-UI/IndustryMain";

const ExecutiveVA = () => {
	const paragraphs = [
		"We match CEOs, leadership teams, and business owners with executive professionals.",
		"Our clients may do more with the help of Cyber Pros virtual professionals, and they can also feel more content and satisfied with the job they accomplish on their own. We remove from your platter everything that isn't deserving of your time, expertise, abilities, or capabilities.",
	];

	const asIs = [
		"Calendar proofing, inbox triage, schedule management, travel planning, expense tracking and reporting, online research, online ordering, social media posting and tracking, CRM updating and management, email marketing coordination, virtual file management and organization, and support in keeping you on track with your commitments and goals",
	];
	return (
		<div>
			<Navbar />
			<MainUI
				title="Executive Virtual Assistant (EVA)"
				ribbon="Boost Efficiency with a Virtual Professional Executive Virtual Assistant (EVA)"
				cardTitle="What An Administrative Virtual Professional Can Do For You"
				paragraphs={paragraphs}
				asIs={asIs}
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default ExecutiveVA;
