import React from "react";
import grow1 from "../../assets/Services/Grow1.jpg";
import "./Grow1.css";

const Grow1 = () => {
  return (
    <section id="grow1" className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-9 col-md-10 mx-auto">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  src={grow1}
                  alt="Grow your Business"
                  className="img-fluid growPic"
                />
              </div>
              <div className="col-md-6">
                <div className="text-light mb-3">
                  <h2 className="big-text">
                    Focusing On <span className="text-danger">Growth</span>
                  </h2>
                </div>
                <div className="text-light">
                  <p className="lil-text">
                    <span>Cyber Pros</span> delivers effective and efficient
                    remote support in administrative, technical or creative
                    areas through a team of skilled professionals. We help
                    businesses boost productivity by managing routine and
                    specialized tasks, enabling business owners to focus on
                    their core activities without the expense or commitment of
                    hiring full time employees.
                  </p>
                  <p className="lil-text">
                    Our virtual professionals are capable of handling everything
                    from office administration to inside sales, support, and
                    beyond. We can help you reduce the time spent on repetitive,
                    tedious tasks while saving up to 60% compared to the cost of
                    hiring a full-time local employee.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Grow1;
