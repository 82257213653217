import React from "react";
import "./BlogSection.css";
import WFHisBetter from "../../assets/Blog/WFHisBetter.png";
import VA101 from "../../assets/Blog/VA101.png";
import SettingUp from "../../assets/Blog/SetupWFH.png";
import { Link } from "react-router-dom";

const BlogCards = ({ title, redirect, pic }) => (
  <div className="col-md-4 col-sm-6 col-12 mb-4">
    <Link to={redirect} className="card text-decoration-none">
      <img src={pic} className="card-img-top" alt={title} />
      <div className="card-body">
        <h5 className="card-title text-center">{title}</h5>
      </div>
    </Link>
  </div>
);

const BlogSection = () => {
  const blogs = [
    {
      title:
        "8 Reason Why working from home is better: A comprehensive look at the Benefits",
      redirect: "/ph/blogs/blog1",
      pic: WFHisBetter,
    },
    {
      title:
        "Virtual Assistant 101: Tips how to become a successful Virtual Assistant",
      redirect: "/ph/blogs/blog2",
      pic: VA101,
    },
    {
      title: "Setting Up Your Home Office: A Guide to Effective Remote Work",
      redirect: "/ph/blogs/blog3",
      pic: SettingUp,
    },
  ];
  return (
    <div id="blogSection">
      <div className="container">
        <div className="row mx-auto justify-content-center">
          {blogs.map((blog, index) => (
            <BlogCards
              key={index}
              title={blog.title}
              redirect={blog.redirect}
              pic={blog.pic}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
