import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/cplogo.png";
import IndustryNav from "./IndustryNav";
import "./Navbar.css";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const location = useLocation();
  const navItems = [
    { label: "About", href: "/about" },
    { label: "Pricing", href: "/pricing" },
    { label: "FAQs", href: "/FAQs" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuOpen(false); // Close the menu on route change
    setModalOpen(false); // Close the modal on route change
  }, [location]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuOpen(false); // Close the menu when a link is clicked
    setModalOpen(false); // Close the modal when a link is clicked
  };

  return (
    <nav className="bg-light text-dark text-center py-3 sticky-top">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center flex-row">
          <NavLink
            to={"/home"}
            onClick={handleClick}
            className="logo-container"
          >
            <img src={Logo} alt="Cyber Pros Logo" className="navbar-logo" />
          </NavLink>
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="navbar-toggler"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
            <nav className={`nav-menu ${menuOpen ? "open" : ""}`}>
              <a
                href="/home"
                className="text-dark mx-3 nav-items text-decoration-none"
                onClick={handleClick}
              >
                Home
              </a>
              <a
                href="/services"
                className="text-dark mx-3 nav-items text-decoration-none"
                onClick={handleClick}
              >
                Services
              </a>
              <button
                className="text-dark mx-3 nav-items text-decoration-none"
                style={{ background: "none", border: "none" }}
                onClick={() => setModalOpen(true)} // Open modal on click
              >
                Industries
              </button>
              {navItems.map((item, index) => (
                <a
                  href={item.href}
                  key={index}
                  className="text-dark mx-3 nav-items text-decoration-none"
                  onClick={handleClick}
                >
                  {item.label}
                </a>
              ))}
              <NavLink
                to="/book"
                className="btn ms-3"
                style={{ borderRadius: "10px" }}
                onClick={handleClick}
              >
                Book a Strategy Call
              </NavLink>
            </nav>
          </div>
        </div>
      </div>
      {modalOpen && (
        <div className="modal-overlay" onClick={() => setModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={() => setModalOpen(false)}>
              <FaTimes size={24} />
            </button>
            <IndustryNav />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
