import React from "react";
import "./Questions.css";

const quests = [
  {
    question: "A virtual professional: What is it?",
    answer:
      "People who work remotely to offer  services are known as virtual professionals. They carry out a variety of duties, including data entry, social media management, research, appointment scheduling, email management, and technical & administrative support. They help people or businesses get things done and increase productivity by using their knowledge, experience, and organizing skills.",
  },
  {
    question: "Will my virtual professional need to be trained?",
    answer:
      "Training is necessary for your virtual professional, just like it is for any other employee. There is no magic bullet that will prevent you from having to spend time teaching your virtual professional about your systems, processes, and business processes because every business is different. Cyber Pros will provide you with the best talent, resources, and tools to ensure that you get started right away.",
  },
  {
    question:
      "How much time does it take to work with Cyber Pros to hire a virtual professonal?",
    answer:
      "In the US, hiring an in-house employee takes 25 days. In as little as one week, Cyber Pros can offer you a variety of highly qualified VPs to choose from and get started right away.",
  },
  {
    question: "How is the hiring procedure carried out?",
    answer:
      "We will recommend a number of virtual professionals from our pool of experts that will match your unique business requirements. Next, you decide which one (or more) you want to begin the onboarding process for.",
  },
  {
    question: "Which services are available from a virtual professional?",
    answer:
      "Any business owner can benefit from a virtual professional's wide range of services, which include bookkeeping, social media management, technical & administrative work, research, customer support, and other specialized tasks depending on the particular needs of the company. If it can be completed remotely with a laptop, a virtual professional probably can.",
  },
  {
    question: "Which sectors gain the most from virtual professional services?",
    answer:
      "Customer service, technology, marketing, sales, real estate, human resources, insurance, and other sectors are those that find the greatest use for virtual professional services. The list is endless. Virtual professional services are a perfect fit for any type of business where work needs to be done remotely and digitally!",
  },
  {
    question: "How is pricing determined?",
    answer:
      "Our clients pay us a fixed monthly fee, and we take care of all insurance, benefits, and paid time off in addition to paying our virtual professionals directly.",
  },
];

const Questions = () => {
  return (
    <div className="QuestionSection">
      <div className="container">
        <h3 className="fw-bold pb-3">Frequently Asked Questions</h3>
        <div className="accordion accordion-flush mb-5" id="FAQ">
          {quests.map((que, index) => (
            <>
              <hr className="my-1" />
              <div className="accordion-item custom-accordion" key={index}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed fw-bold"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#q${index}`}
                    aria-expanded="false"
                    aria-controls={`q${index}`}
                  >
                    {que.question}
                  </button>
                </h2>
                <div
                  id={`q${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#FAQ"
                >
                  <div className="accordion-body">{que.answer}</div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
