import React from "react";
import "./BePart.css";

const BePart = () => {
  return (
    <div id="bepart">
      <div className="">
        <h1 className="fst-italic fw-bolder mb-5 text-light">
          Apply Now and Become a<br />
          Part of the Cyber Pros Family
        </h1>
        <a
          href="/ph/apply"
          className="btn rounded-pill px-5 fs-4 fw-semibold fst-italic"
        >
          Apply Now
        </a>
      </div>
    </div>
  );
};

export default BePart;
