import React from "react";
import Navbar from "../components-PH/Navbar-Phase1/Navbar";
import Footer from "../components-PH/Footer/Footer";
import Forms from "../components-PH/ApplyNow_Components/Forms";

const Apply = () => {
	return (
		<div>
			<Navbar />
			<Forms />
			<Footer />
		</div>
	);
};

export default Apply;
