// src/getLocation.js
import axios from 'axios';

const API_KEY = '7e8c07c696514bf1b7e3a0d7b6c2caec';

const getLocation = async () => {
  try {
    const response = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}`);
    console.log('Location data:', response.data); // Log the location data
    return response.data;
  } catch (error) {
    console.error('Error fetching location data:', error);
    return null;
  }
};

export default getLocation;
