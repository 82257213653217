import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import MainUI from "../Inudstry-UI/IndustryMain";
import MTDIcon from "../../../assets/Industry/TransacCoor/MTD.png";
import MEPIcon from "../../../assets/Industry/TransacCoor/MEP.png";
import ICIcon from "../../../assets/Industry/TransacCoor/IC.png";
import IAIcon from "../../../assets/Industry/TransacCoor/IA.png";
import CMIcon from "../../../assets/Industry/TransacCoor/CM.png";
import CCAIcon from "../../../assets/Industry/TransacCoor/CCA.png";

const TransactionCoordinator = () => {
	const btns = [
		{
			target: "MTD",
			label: "Manage Transaction Documentation",
		},
		{
			target: "MEP",
			label: "Manage Escrow Process",
		},
		{
			target: "IC",
			label: "Interview Coordination",
		},
		{
			target: "IA",
			label: "Inspection & Appraisals",
		},
		{
			target: "CM",
			label: "Checklist Management",
		},
		{
			target: "CCA",
			label: "Coordinate Closing Activities",
		},
	];

	const contents = [
		{
			target: "MTD",
			icon: MTDIcon,
			title: "Manage Transaction Documentation",
			tasks: [
				"Check contract addenda, initials, and signatures.",
				"During the contingency period, keep an eye on deadlines and send out notifications.",
				"Verify that the loan underwriter possesses the required licenses and clearances.",
			],
		},
		{
			target: "MEP",
			icon: MEPIcon,
			title: "Manage Escrow Process",
			tasks: [
				"Examine the escrow instructions and the purchase agreement before opening escrow.",
				"Keep track of the financing, closing, and property inspection processes.",
				"Organize communication between the buyer, seller, escrow, and other parties.",
			],
		},
		{
			target: "IC",
			icon: ICIcon,
			title: "Interview Coordination",
			tasks: [
				"Update and communicate with all parties on a regular basis.",
				"Send out weekly summaries and arrange for the walkthrough at the end.",
				"Before closing, audit the file to make sure all the paperwork is completed.",
			],
		},
		{
			target: "IA",
			icon: IAIcon,
			title: "Inspection & Appraisals",
			tasks: [
				"Plan appraisals, schedule property inspections, and more.",
				"Make sure you get the required reports as soon as possible.",
				"Draft disclosure packets for sellers and addenda.",
			],
		},
		{
			target: "CM",
			icon: CMIcon,
			title: "Checklist Management",
			tasks: [
				"Establish and keep up-to-date thorough transaction checklists.",
				"Make sure that each stage of the procedure is recognized and finished.",
				"Enter every client's detail in the database.",
			],
		},
		{
			target: "CCA",
			icon: CCAIcon,
			title: "Coordinate Closing Activities",
			tasks: [
				"Organize the mortgage loan, appraisal, and title/escrow procedures.",
				"Organize inspections and support the negotiation and completion of repairs.",
				"Send in the required paperwork and arrange for the closing procedure.",
			],
		},
	];

	const paragraphs = [
		"Our knowledgeable TC professionals can take care of the crucial duties involved in transaction management, freeing up your team's time so they can concentrate on building rapport with clients and completing deals.",
		"An expert in real estate who oversees all the paperwork and deadlines associated with a transaction is known as a coordinator of real estate transactions. They are entrusted with overseeing the process from the moment the seller accepts an offer until the escrow closes, and they are trained in every phase of the selling procedure.",
		"They collaborate closely with all involved parties to guarantee a seamless transaction by offering comprehensive and practical information, organizing and arranging inspections, processing disclosures and contractual paperwork, keeping an eye on deadlines, and processing disclosures and contractual documents.",
	];
	return (
		<div>
			<Navbar />
			<MainUI
				title="Transaction Coordinator"
				ribbon="Boost Efficiency with a Virtual Professional Transaction Coordinator (TC)"
				cardTitle="What An Transaction Coordinator Virtual Professional Can Do For You"
				paragraphs={paragraphs}
				tabInit="MTD"
				btns={btns}
				contents={contents}
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default TransactionCoordinator;
