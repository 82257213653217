import React from "react";
import "./CompanyProfile.css";
import imgProfile from "../../../assets/Career/Section4.png";

const CompanyProfile = () => {
  return (
    <section id="companyprofile" className="py-5">
      <div className="container ">
        <div className="row">
          <div className="col-md-8 py-5">
            <div
              className="card card-profile"
              style={{ fontFamily: "Inter, sans-serif" }}>
              <div className="card-body">
                <h2
                  style={{ color: "#1A81FD", fontWeight: 700 }}
                  className="lead">
                  Our Company
                </h2>
                <p className="lead" style={{ fontWeight: 700 }}>
                  Culture
                </p>
                <p style={{ textAlign: "justify" }}>
                  At{" "}
                  <span style={{ fontWeight: 700, fontSize: "13px" }}>
                    Cyber Pros
                  </span>
                  , our company culture is built on mutual respect,
                  collaboration, and continuous learning. We foster an
                  environment where every voice is heard, providing ample
                  opportunities for skill development through training sessions
                  and workshops. Our flexible work schedules and remote work
                  options help maintain a healthy work-life balance.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Diversity and inclusion are central to our culture, creating a
                  welcoming and valued workplace. We recognize hard work with
                  employee recognition programs and performance bonuses, and
                  encourage participation in volunteer activities and social
                  initiatives. Regular team-building activities and wellness
                  programs keep our employees engaged and connected.{" "}
                </p>
                <p>
                  Join <span style={{ fontWeight: 700 }}>Cyber Pros</span> and
                  thrive in a vibrant, supportive community.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 py-5">
            <img src={imgProfile} alt="Company" className="img-fluid rounded" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyProfile;
