import React from "react";
import "./Success.css";
import img from "../../../assets/About/section2.png";

import suitcase from "../../../assets/About/suitcase.png";
import WIFI from "../../../assets/About/growth.png";
import CloudTalk from "../../../assets/About/result.png";

const successList = [
	{
		icon: suitcase,
		title: "Career",
		text: "Fuel Your Team's Growth.",
	},
	{
		icon: WIFI,
		title: "Growth",
		text: "Unlock Sustainable  Business Expansion.",
	},
	{
		icon: CloudTalk,
		title: "Result",
		text: "Drive Measurable Outcome.",
	},
];

const ServiceCard = ({ title, text, icon }) => (
	<div className="col-md-4">
		<div className="card text-center border-0 shadow-none">
			<div className="card-body">
				<img src={icon} alt={title} />
				<h5 className="card-title mt-2">{title}</h5>
				<p className="card-text">{text}</p>
			</div>
		</div>
	</div>
);

const Success1 = () => {
	return (
		<>
			<section id="success1" className="py-5">
				<div className="container">
					<div className="d-flex justify-content-center">
						<img src={img} alt="Success1" className="mb-4" />
					</div>

					<div className="text-center btn-text mt-4">
						<div className="container">
							<div className="row mt-4">
								{successList.map((service, index) => (
									<ServiceCard
										key={index}
										icon={service.icon}
										title={service.title}
										text={service.text}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Success1;
