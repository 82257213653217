import React from "react";
import { NavLink } from "react-router-dom";
import "./What1.css";

const What1 = () => (
  <section id="services" className="py-5">
    <div className="container">
      <h2 className="text-center">
        WHAT ARE YOU <span className="text-primary">WAITING</span> FOR?
      </h2>

      <div className="text-center btn-text mt-4">
        <p className="mx-auto my-5">
          Take the first step towards a rewarding and flexible career. Apply now
          and experience the benefits of being a virtual professional at Cyber
          Pros.
        </p>
        <NavLink to="/ph/apply" className="btn rounded-pill px-5">
          APPLY NOW!
        </NavLink>
      </div>
    </div>
  </section>
);

export default What1;
