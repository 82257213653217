import React from "react";
import "./HireVA.css";
import VA1 from "../../../assets/Pricing/Icons/VA1.png";
import VA2 from "../../../assets/Pricing/Icons/VA2.png";
import VA3 from "../../../assets/Pricing/Icons/VA3.png";
import { NavLink } from "react-router-dom";

const HireVA = () => {
  return (
    <section className="hire-va-section">
      <div className="container">
        <h2>
          Hire a VP with <span className="highlight">Cyber Pros</span>
        </h2>
        <p>In 3 easy steps</p>
        <div className="row">
          <div className="col-12 col-md-12 mx-auto">
            <div className="row steps">
              <div className="col-12 col-sm-4 step">
                <div className="stepIcon mx-auto">
                  <div className="numb">1</div>
                  <img src={VA1} alt="FreeConsult" />
                </div>
                <p>Start your free consultation</p>
              </div>
              <div className="col-12 col-sm-4 step">
                <div className="stepIcon mx-auto">
                  <div className="numb">2</div>
                  <img src={VA2} alt="MeetTeam" />
                </div>
                <p>Meet with our Team</p>
              </div>
              <div className="col-12 col-sm-4 step">
                <div className="stepIcon mx-auto">
                  <div className="numb">3</div>
                  <img src={VA3} alt="HireVA" />
                </div>
                <p>Hire your Virtual Professional</p>
              </div>
            </div>
          </div>
        </div>
        <NavLink to={"/book"} className="btn strategy-call-button">
          Book a strategy call
        </NavLink>
      </div>
    </section>
  );
};

export default HireVA;
