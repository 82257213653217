import React from "react";
import "./Expect.css";
import { PiClockCountdown } from "react-icons/pi";
import { FaRegLightbulb } from "react-icons/fa";
import { LuTarget } from "react-icons/lu";

const expect = [
	{
		icon: <PiClockCountdown />,
		title: "STRATEGY SESSION",
		text: "Our advisors will do a thorough analysis of your company's objectives and issues in order to find areas where a virtual assistant can help.",
	},
	{
		icon: <FaRegLightbulb />,
		title: "Create Plan With Our Team",
		text: "Collaborate with our expert to create a custom roadmap. Define tasks, provide seamless process integration, and provide clear success criteria.",
	},
	{
		icon: <LuTarget />,
		title: "Interview & Select Talent",
		text: "Define tasks, provide seamless workflow integration, and establish clear success measures.",
	},
];

const ExpectCard = ({ title, text, icon }) => (
	<div className="col-md-4 mb-4 d-flex justify-content-center">
	  <div className="text-center text-light">
		<div className="expectIcon m-auto">
		  <h3>{icon}</h3>
		</div>
		<h5 className="card-title text-uppercase mb-3">{title}</h5>
		<p className="card-text">{text}</p>
	  </div>
	</div>
  );
  

const Expect = () => (
	<section id="expect" className="py-5">
		<div className="container">
			<h2 className="text-center">WHAT TO EXPECT?</h2>
			<div className="row mt-4">
				{expect.map((expect, index) => (
					<ExpectCard
						key={index}
						icon={expect.icon}
						title={expect.title}
						text={expect.text}
					/>
				))}
			</div>
		</div>
	</section>
);

export default Expect;
