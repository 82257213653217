import React, { useRef } from "react";
import Navbar from "../components-PH/Navbar-Phase1/Navbar";
import Footer from "../components-PH/Footer/Footer";
import WelcomeAbout from "../components-PH/About_Components/Home-Section/WelcomeAbout";
import Time from "../components-PH/About_Components/Time/Time";
import Success from "../components-PH/About_Components/Success/Success";
import Success1 from "../components-PH/About_Components/Success/Success1";
import BePart from "../components-PH/BePart/BePart";
// import Team from "../components-PH/About_Components/Team/Team";
import What1 from "../components-PH/Phase1_Components/What/What1";

const About = () => {
  const successRef = useRef(null);

  const scrollSuccess = () => {
    successRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Navbar />
      <WelcomeAbout scrollSuccess={scrollSuccess} />
      <Time />
      <Success successRef={successRef} />
      <Success1 />
      <BePart />
      {/* <Team /> */}
      <What1 />
      <Footer />
    </div>
  );
};

export default About;
