import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import AdminUI from "../Inudstry-UI/IndustryMain";
import ROMIcon from "../../../assets/Industry/Admin/ROM.png";
import AABIcon from "../../../assets/Industry/Admin/ANB.png";
import HPRIcon from "../../../assets/Industry/Admin/HRPR.png";
import DMIcon from "../../../assets/Industry/Admin/DM.png";
import PCIcon from "../../../assets/Industry/Admin/PCnAHT.png";
import ETIcon from "../../../assets/Industry/Admin/ET.png";
import MSIcon from "../../../assets/Industry/Admin/MS.png";

const AdminService = () => {
	const btns = [
		{
			target: "ROM",
			label: "Remote Office Management",
		},
		{
			target: "AAB",
			label: "Accounting and Bookkeeping",
		},
		{
			target: "HPR",
			label: "HR + Recruitment",
		},
		{
			target: "DM",
			label: "Document Management",
		},
		{
			target: "PCnAHT",
			label: "Phone Calls & AD HOC Tasks",
		},
		{
			target: "ET",
			label: "Executive Tasks",
		},
		{
			target: "MS",
			label: "Manage Schedule",
		},
	];

	const contents = [
		{
			target: "ROM",
			icon: ROMIcon,
			title: "Remote Office Management",
			tasks: [
				"Calendar and Email Management",
				"Ensuring system functionality, database management, and back-office assistance",
				"Arranging meetings by establishing a schedule, an agenda, and logistics",
			],
		},
		{
			target: "AAB",
			icon: AABIcon,
			title: "Accounting and Bookkeeping",
			tasks: [
				"Preserving financial documentation, including accounts payable and receivable",
				"Billing and balancing bank accounts",
				"Overseeing and monitoring operational expense reimbursements",
			],
		},
		{
			target: "HPR",
			icon: HPRIcon,
			title: "HR + Recruitment",
			tasks: [
				"Assessing possible hires and supporting the acceptance and rejection procedures",
				"Contacting applicants and keeping track of open positions",
				"Taking care of scheduling, payroll, and open positions ",
			],
		},
		{
			target: "DM",
			icon: DMIcon,
			title: "Document Management",
			tasks: [
				"Using computers to create and format documents, such as spreadsheets, letters, and status reports.",
				"Storing and managing a range of business documents digitally",
				" Running reports and updating CRMs",
			],
		},
		{
			target: "PCnAHT",
			icon: PCIcon,
			title: "Phone Calls & AD HOC Tasks",
			tasks: [
				"Phoning and email correspondence screening",
				"Approving and modifying the time of meetings",
				"Arranging and directing conferences and other activities",
			],
		},
		{
			target: "ET",
			icon: ETIcon,
			title: "Executive Tasks",
			tasks: [
				"Completing data entry tasks such as exporting data, running queries, and entering contacts",
				"Preserving referrals, generating and revising templates within the CRM or database",
				"Monitoring and completing leads",
			],
		},
		{
			target: "MS",
			icon: MSIcon,
			title: "Manage Schedule",
			tasks: [
				"As needed, schedule sales meetings, account service start and end dates, and future escalation calls",
				"Assistance with sticking to your schedule and providing prompt customer service",
			],
		},
	];

	const paragraphs = [
		"Work that is overly administrative can be draining. But we are aware of the significance of these assignments and the length of time required to complete them.",
		"Our administrative virtual professionals, also known as administrative VPs, are experts at a wide range of tasks, including bookkeeping, expense accounting, executive duties, CRM, paperwork, calendar scheduling, and more. This allows you to concentrate on the crucial tasks that propel your company forward.",
	];
	return (
		<div>
			<Navbar />
			<AdminUI
				tabInit="ROM"
				title="ADMINISTRATIVE"
				ribbon="Increase Sales and Efficiency With A Virtual Administrative Professional"
				paragraphs={paragraphs}
				cardTitle="What An Administrative Virtual Professional Can Do
							For You"
				btns={btns}
				contents={contents}
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default AdminService;
