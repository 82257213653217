import React from "react";
import "./SysReq.css";
import WindowsLogo from "../../assets/Home/Home1/windows.png";
import MacLogo from "../../assets/Home/Home1/mac.png";
import Info from "../../assets/Home/Home1/info.png";

const Windows = () => {
  return (
    <ul className="sys-req-list">
      <li>1 Fully Functioning 64Bit Desktop/Laptop</li>
      <li>Windows10 [64Bit Only]</li>
      <li>Intel Core i5 4th+ gen or higher (or AMD equivalent)</li>
      <li>8 GB RAM or more For multitasking requirements</li>
      <li>USB Headset with Noise-Cancelling Feature</li>
      <li>Internet Service of 10Mbps and up (DSL, Fiber, or Cable only)</li>
      <li>Backup internet service of 25Mbps or more</li>
      <li>Workstation free from any Noise and Distractions</li>
      <li>Backup for Power Outage: Generator/UPS or Alternative Location</li>
      <li>Webcam with Full HD (1080p) video capture</li>
    </ul>
  );
};

const Mac = () => {
  return (
    <ul className="sys-req-list">
      <li>1 Fully Functioning 64Bit Desktop/Laptop </li>
      <li>macOS Catalina and newer</li>
      <li>Intel or Silicon(M1+) chip</li>
      <li>8 GB RAM or more For multitasking requirements</li>
      <li>USB Headset with Noise-Cancelling Feature</li>
      <li>Internet Service of 10Mbps and up (DSL, Fiber, or Cable only)</li>
      <li>Backup internet service of 25Mbps or more</li>
      <li>Workstation free from any Noise and Distractions</li>
      <li>Backup for Power Outage: Generator/UPS or Alternative Location</li>
      <li>Webcam with Full HD (1080p) video capture</li>
    </ul>
  );
};
const SysReq = () => {
  return (
    <>
      <div id="sysreq">
        <h1 className="text-center mb-5 fw-bold">System Requirements</h1>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 col-sm-12 mb-5">
              <div className="card rounded-5">
                <div className="imgBg text-center">
                  <img src={WindowsLogo} alt="Windows" />
                </div>
                <div className="card-body">
                  <Windows />
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-12 mb-5">
              <div className="card rounded-5">
                <div className="imgBg text-center">
                  <img src={MacLogo} alt="Mac" />
                </div>
                <div className="card-body">
                  <Mac />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="fill">
        <div className="text-center">
          <img src={Info} alt="info" className="infoPic" />
          <blockquote className="text-white mx-auto mt-3">
            For all positions, the aforementioned system requirements are
            mandatory. After the interview is successful, backups of a desktop
            computer, laptop, and power supply can be given. Before submitting
            an application, please make sure these systems are prepared.
          </blockquote>
        </div>
      </div>
    </>
  );
};

export default SysReq;
