import React from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import Book from "../../Book/Book";
import MainUI from "../Inudstry-UI/IndustryMain";
import CSIcon from "../../../assets/Industry/CustServ/CS.png";
import TMIcon from "../../../assets/Industry/CustServ/TM.png";
import CFIcon from "../../../assets/Industry/CustServ/CF.png";
import ASIcon from "../../../assets/Industry/CustServ/AS.png";
import SMIcon from "../../../assets/Industry/CustServ/SM.png";

const CustomerService = () => {
	const btns = [
		{
			target: "CS",
			label: "Customer Support & Communication",
		},
		{
			target: "TM",
			label: "Ticket Management",
		},
		{
			target: "CF",
			label: "Customer Feedback",
		},
		{
			target: "AS",
			label: "Administrative Support",
		},
		{
			target: "SM",
			label: "Schedule Management",
		},
	];

	const contents = [
		{
			target: "CS",
			icon: CSIcon,
			title: "Customer Support & Communication",
			tasks: [
				"Answer questions from customers regarding products, procedures, warranties, and other topics",
				"Efficiently serve as a liaison and go-between for communications to guarantee that client communications are promptly answered and forwarded to the appropriate stakeholder",
				"Engage in email, SMS, live chat, online phone systems, and phone conversations with clients using VoIP or online phone systems.",
			],
		},
		{
			target: "TM",
			icon: TMIcon,
			title: "Ticket Management",
			tasks: [
				"Answer questions from customers regarding products, procedures, warranties, and other topics",
				"Efficiently serve as a liaison and go-between for communications to guarantee that client communications are promptly answered and forwarded to the appropriate stakeholder",
				"Engage in email, SMS, live chat, online phone systems, and phone conversations with clients using VoIP or online phone systems",
			],
		},
		{
			target: "CF",
			icon: CFIcon,
			title: "Customer Feedback",
			tasks: [
				"Gather client opinions through focused questionnaires and other feedback programs",
				"Keep an eye on customer comments and reviews on review websites and social media platforms",
			],
		},
		{
			target: "AS",
			icon: ASIcon,
			title: "Administrative Support",
			tasks: [
				"Gather client opinions through focused questionnaires and other feedback programs",
				"Keep an eye on customer comments and reviews on review websites and social media platforms",
			],
		},
		{
			target: "SM",
			icon: SMIcon,
			title: "Schedule Management",
			tasks: [
				"As needed, schedule client installations, upgrades, account service start and end dates, and impending escalation calls",
				"Help keep the internal timetable up to date in order to provide prompt customer service",
			],
		},
	];

	const paragraphs = [
		"In the business world, you have to support more current and potential customers as you grow in success. If you're not careful, answering all of these questions could become a bottleneck that hinders your business's growth and creates a bad customer experience, which could result in negative reviews and higher attrition.",
		"A virtual professional for customer service who works remotely manages trouble tickets, communicates with clients via email and live chat, and provides administrative support. Our virtual professionals can work within business to business or business to customer activities to provide customer service and support, from a single VP to department level staffing. This allows you to handle as many inquiries as necessary without letting staffing concerns divert you from your primary goal.",
		"As experts in assisting businesses at every stage of their lifecycle, we at Cyber Pros have firsthand experience with the detriments of having insufficient customer service and support staff. Because of this, we take great satisfaction in providing you with the best virtual professionals to manage your customer service responsibilities, enabling you to quickly grow to meet demand.",
	];
	return (
		<div>
			<Navbar />
			<MainUI
				tabInit="CS"
				title="CUSTOMER SERVICE"
				ribbon="Expand Your Company With Round-the-Clock Support & Customer Service"
				cardTitle="What An Customer Service Virtual Professional Can Do For You"
				paragraphs={paragraphs}
				btns={btns}
				contents={contents}
			/>
			<Book />
			<Footer />
		</div>
	);
};

export default CustomerService;
