import React from "react";
import { FaCheck } from "react-icons/fa";

const Banner = () => {
  const contents = [
    "Expertise and Reliability: Our experienced team provides top-notch services with a commitment to quality and reliability, ensuring your business processes run smoothly and efficiently.",
    "Customized and Scalable Services: Our solutions are tailored to your specific needs and scalable to grow with your business, providing the flexibility required to adapt to changing demands.",
    "Customer-Centric Approach: Your satisfaction is our priority. We work closely with you to understand your needs and provide personalized support, ensuring the highest level of customer service.",
  ];
  return (
    <div className="banner py-5">
      <div className="container">
        <div className="row text-light">
          <div className="col-6">
            <h1 style={{ fontSize: "4rem" }}>
              Scale Your Business With The #1 Virtual Professional Company
            </h1>
          </div>
          <div className="col-6">
            {contents.map((content, index) => (
              <div className="d-flex" key={index}>
                <div className="me-3 text-warning align-baseline">
                  <FaCheck />
                </div>
                <p>{content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
