import React from "react";
import { GiClick } from "react-icons/gi";
import TabPane from "./TabPane";
import "./adminUI.css";
import TabPlain from "./TabPlain";

const AdminUI = ({
	title,
	ribbon,
	paragraphs,
	cardTitle,
	tabInit,
	btns,
	contents,
	asIs,
	addedClass,
}) => {
	return (
		<div id="adminUI">
			<div className="container">
				<h1
					className="mt-5 fw-bold"
					style={{ color: "var(--main-color)" }}
				>
					{title}
				</h1>
			</div>

			<div style={{ background: "var(--main-color-hover)" }}>
				<div className="container">
					<p
						className="w-bold fs-4 py-3 text-light fw-semibold"
						style={{ width: "400px" }}
					>
						{ribbon}
					</p>
				</div>
			</div>

			<div className="container">
				{paragraphs.map((sentence, index) => (
					<blockquote className="mt-4" key={index}>
						{sentence}
					</blockquote>
				))}

				<div className="my-5 p-4 border border-black border-2 rounded-3">
					<div className="d-flex align-items-start">
						<p className="fs-5 me-2 my-0">
							<GiClick />
						</p>
						<h4 className="d-flex align-items-start fw-bold">
							{cardTitle}
						</h4>
					</div>

					{tabInit ? (
						<TabPane
							tabInit={tabInit}
							btns={btns}
							contents={contents}
						/>
					) : (
						<TabPlain asIs={asIs} addedclassName={addedClass} />
					)}
				</div>
			</div>
		</div>
	);
};

export default AdminUI;
