import React from "react";
import Ethics from "../../../assets/Career/suitcase.png";
import Growth from "../../../assets/Career/growth.png";
import Result from "../../../assets/Career/result.png";

const services = [
  {
    icon: Ethics,
    title: "Ethics",
    text: "At Cyber Pros, we uphold high ethical standards, respect diversity, and act with transparency. We do the right thing, always.",
  },
  {
    icon: Growth,
    title: "Growth",
    text: "Your growth is our focus. We offer career advancement, skill development, and continuous learning to help you succeed.",
  },
  {
    icon: Result,
    title: "Result",
    text: "We value results. At Cyber Pros, your contributions make a difference. Join us to achieve excellence and impact your career.",
  },
];

const ServiceCard = ({ title, text, icon }) => (
  <div className="col-md-4">
    <div className="card text-center border-0">
      <div className="card-body">
        <img src={icon} alt={title} />
        <h5 className="card-title">{title}</h5>
        <p className="card-text ">{text}</p>
      </div>
    </div>
  </div>
);

const Why = () => {
  return (
    <section
      id="whycareer"
      className="py-5"
      style={{ fontFamily: "Inter, sans-serif" }}
    >
      <div className="container">
        <h2 className="text-center py">
          WHY <span className="text-primary">JOIN</span> US!
        </h2>
        <h2
          className="text-center py-4 d-flex justify-content-center align-items-center w-75 mx-auto"
          style={{ fontSize: "25px" }}
        >
          Highlight the benefits of working at your company, such as career
          growth opportunities, work-life balance, and company culture.
        </h2>
        <div className="row my-4">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              icon={service.icon}
              title={service.title}
              text={service.text}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Why;
