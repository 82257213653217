import React, { useState, useEffect } from "react";
import Logo from "../../assets/cplogo.png";
import { NavLink, useLocation } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navItems = [
    { label: "Home", href: "/ph/home" },
    { label: "About", href: "/ph/about" },
    { label: "Career", href: "/ph/career" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsOpen(false); // Close the menu when a link is clicked
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-md bg-light text-dark text-center sticky-top">
      <div className="container">
        <NavLink to={"/ph/home"} className="navbar-brand">
          <img src={Logo} alt="Cyber Pros Logo" />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={isOpen ? "true" : "false"}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            {navItems.map((item, index) => (
              <li className="nav-item" key={index}>
                <NavLink
                  to={item.href}
                  className="nav-link text-dark mx-3"
                  onClick={handleClick}
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
            <li className="nav-item">
              <NavLink
                to="/ph/apply"
                className="btn ms-3"
                onClick={handleClick}
              >
                APPLY NOW
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
