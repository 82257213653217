import React from "react";
import Navbar from "../components-PH/Navbar-Phase1/Navbar";
import Footer from "../components-PH/Footer/Footer";
import WelcomeCareer from "../components-PH/Career_Components/1-Section/WelcomeCareer";
import Why from "../components-PH/Career_Components/2-Section/Why";
import CompanyMV from "../components-PH/Career_Components/3-Section/CompanyMV";
import Looking from "../components-PH/Career_Components/4-Section/Looking";
import CompanyProfile from "../components-PH/Career_Components/5-Section/CompanyProfile";

const Career = () => {
	return (
		<div>
			<Navbar />
			<WelcomeCareer />
			<Why />
			<CompanyMV />
			<Looking />
			<CompanyProfile />
			<Footer />
		</div>
	);
};

export default Career;
