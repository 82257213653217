import React, { useEffect, useRef } from "react";
import Odometer from "odometer";
import "odometer/themes/odometer-theme-default.css";
import "./Time.css";

const OdometerComponent = ({ className, value }) => {
  const odometerRef = useRef(null);

  useEffect(() => {
    const odometer = new Odometer({
      el: odometerRef.current,
      value: 0,
    });

    let hasRun = false;

    const options = {
      threshold: [0, 0.9],
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasRun) {
          odometer.update(value);
          hasRun = true;
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(odometerRef.current);

    return () => observer.disconnect();
  }, [value]);

  return (
    <div ref={odometerRef} className={`odometer ${className}`}>
      0
    </div>
  );
};

const Time = () => {
  return (
    <section id="office" className="py-5">
      <div className="container">
        <div className="row mt-4">
          <div className="stats-container">
            <div className="stat">
              <OdometerComponent
                className="subscribers-odometer plus"
                value={1827}
              />
              <div className="type">Coffee Cups</div>
            </div>

            <div className="stat">
              <OdometerComponent className="videos-odometer plus" value={790} />
              <div className="type">Finished Projects</div>
            </div>

            <div className="stat">
              <OdometerComponent className="projects-odometer" value={223} />
              <div className="type">Employees</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Time;
