import React from "react";
import "./WelcomeAbout.css";
import grow1 from "../../../assets/About/section 1 overlay.png";

const WelcomeAbout = ({ scrollSuccess }) => {
  return (
    <section id="welcomeAbout">
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-md-6 col-12 mt-5">
            <h1 className="mb-3 welcome-text">
              WE HELP YOU TO <span className="text-primary">COMPLETE</span> YOUR
              <span className=""> BEST WORK</span>
            </h1>

            <button className="btn rounded-pill px-5" onClick={scrollSuccess}>
              What We Do
            </button>
          </div>
          <div className="col-md-6 col-12 mt-4 d-none d-md-block">
            <img
              src={grow1}
              alt="Grow your Business"
              className="img-fluid growPic rounded"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeAbout;
