import React, { useState } from "react";
import "./Hero3.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Hero3 = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    companyName: "",
    video: null,
    industry: "",
    services: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        form.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post(
        "http://localhost/api/bookStrategy.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response data:", response.data); // Debugging

      if (response.data.success) {
        toast.success("Book a strategy call was submitted successfully!");

        // Reset form data
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          companyName: "",
          video: null,
          industry: "",
          services: "",
        });
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error(
        "Unable to process your request at this time. Please try again."
      );
      console.error(
        "Action failed. Please contact support if the issue persists",
        error
      );
    }
  };

  const industryOptions = [
    "Accounting and Bookkeeping",
    "Engineering/Construction",
    "Health Care",
    "Human Resource",
    "Information and Technology",
    "Insurance",
    "Legal",
    "Logistics",
    "Marketing",
    "Real Estate",
    "Others",
  ];

  const servicesOptions = [
    "Administrative",
    "Customer service",
    "Marketing",
    "Inside sales agent",
    "Sales development",
    "Transaction coordinator",
    "Executive virtual assistant",
    "Information technology",
    "Engineering support",
    "Real Estate",
    "Information Technology",
    "Insurance",
    "Human resource",
    "Legal",
    "Logistics",
    "Accounting and bookkeeping",
    "Healthcare",
    "Engineering/Construction",
    "Others",
  ];

  return (
    <section id="hero3">
      <ToastContainer /> {/* Ensure this is properly placed */}
      <div className="bg-blur"></div>
      <div
        className="container d-flex align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="row w-100 mx-0">
          <div className="col-lg-6 d-none d-lg-block position-relative d-flex justify-content-center align-items-center">
            <div className="circle1"></div>
            <div className="circle2"></div>
            <div className="triangle1"></div>
            <div className="triangle2"></div>
            <div className="circle3"></div>
          </div>
          <div className="col-lg-6 col-12 align-items-center mb-5">
            <div className="me-5">
              <h1 className="text-light fw-bolder mb-5">
                Strategy Call with an <span>Expert</span>
              </h1>
              <form onSubmit={handleSubmit} className="heroForm">
                <div className="row mb-2">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="firstName" className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="lastName" className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="email" className="form-label">
                    Business Email Address{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="row mb-2">
                  <div className="col-md-6 mb-2">
                    <label htmlFor="industry" className="form-label">
                      Your Business Industry{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      name="industry"
                      value={formData.industry}
                      onChange={handleChange}
                      className="form-select"
                      required
                    >
                      <option value="">Select Industry</option>
                      {industryOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="services" className="form-label">
                      Service Needed <span className="text-danger">*</span>
                    </label>
                    <select
                      name="services"
                      value={formData.services}
                      onChange={handleChange}
                      className="form-select"
                      required
                    >
                      <option value="">Select Services</option>
                      {servicesOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>

                <button type="submit" className="btn rounded-pill px-3">
                  Book a strategy call
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero3;
