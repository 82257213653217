import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero/Hero";
import Services from "../components/Services/Services";
import Grow from "../components/Grow/Grow";
import Footer from "../components/Footer/Footer";

const Phase2 = () => {
	return (
		<div>
			<Navbar />
			<Hero />
			<Services />
			<Grow />
			<Footer />
		</div>
	);
};

export default Phase2;
